import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
} from "react";
import DashboardNavbar from "./Dashboard/DashboardNavbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import img from "./images/details-img.png";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import img2 from "./images/details-img2.png";
import calendar from "./images/dashboard-vector-calendar.svg";
import card from "./images/dashboard-vector-card.svg";
import { Dialog, Transition } from "@headlessui/react";
import { getToken, creditCard, isAuth } from "./helpers/auth";
import { ImageViewer } from "react-image-viewer-dv";
import mastercard from "./images/mastercard.svg";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "./css/card.css";
import useForm from "./forms/useForm";
import validate from "./forms/LoginFormValidationRules";
import JoditEditor from "jodit-react";
import PropagateLoader from "./PropagateLoader.tsx";

export default function NewFeedDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    blogs: [],
    gallery: [],
  });

  const { values, errors } = useForm(login, validate);

  const { blogs } = data;

  function login() {}

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const signInUser = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}auth/login`, {
        email,
        password,
      })
      .then((response) => {
        const { email, name, token } = response?.data;
        localStorage.setItem("user", JSON.stringify({ email, name, token }));
        if (response.data?.card) {
          localStorage.setItem("card", JSON.stringify(response.data?.card));
        }
        setOpenModal(false);
      })
      .catch((err) => {});
  };

  const handleChangeSignIn = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value });
  };

  const [checked, setChecked] = useState(false);

  const [checkout, setCheckout] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}user/news_feed/${id}`)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
        if (creditCard() !== null) {
          setCheckout(0);
        }
      })
      .catch((err) => {});
  }, [id]);

  const donate = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}user/payment/donation/${id}`,
        {
          amount,
          months,
          currency,
        },
        {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then(() => {
        setOpenModal(false);
        setAmount(undefined);
        setMonths(1);
      })
      .catch((err) => {});
  };

  // PAYMENT
  const PaymentForm = () => {
    const stripe = loadStripe(
      "pk_test_51KjLnwIKpe12arm6Ojasg7VW7cfvysr3Zss4fTNzzp5jyspk0mbmRFaAfxtCFek4RriiFWMCZZWIQdVhy0hCKUXX00lYe4Xlvw"
    );
    return (
      <Elements stripe={stripe}>
        <CheckoutForm />
      </Elements>
    );
  };

  function CheckoutForm() {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const addCard = async (e) => {
      e.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      setPaymentLoading(true);
      // Save card at stripe
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      setPaymentLoading(false);

      let card = {
        exp_month: result?.paymentMethod.card.exp_month,
        exp_year: result?.paymentMethod.card.exp_year,
        last4: result?.paymentMethod.card.last4,
      };
      // Set card on local storage
      localStorage.setItem("card", JSON.stringify(card));
      setCheckout(0);

      if (result.error) {
        alert(result.error.message);
      }
      // Save card in DB
      await axios.post(
        `${process.env.REACT_APP_API_URL}user/add/card`,
        {
          payment_method: result.paymentMethod.id,
        },
        {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        }
      );
    };

    return (
      <form className="w-10/12 md:w-9/12 mx-auto" onSubmit={addCard}>
        <div className="relative w-full flex flex-col">
          <CardElement
            className="card w-full"
            options={{
              style: {
                base: {
                  backgroundColor: "white",
                  color: "#20484F",
                },
              },
            }}
          />
          <div className="w-full flex justify-end mt-3">
            <button
              className="py-2 px-3 text-white font-medium rounded-2xl bg-darkgreen"
              disabled={isPaymentLoading}
            >
              {isPaymentLoading ? "Loading..." : "Continua"}
            </button>
          </div>
        </div>
      </form>
    );
  }

  const [months, setMonths] = useState(1);

  const incrementMonths = () => {
    setMonths(months + 1);
  };

  const decrementMonths = () => {
    if (months <= 1) {
      return;
    }
    setMonths(months - 1);
  };

  const [amount, setAmount] = useState();

  const handleAmount = (e) => {
    setAmount(e.target.value);
  };

  const [currentItem, setCurrentItem] = useState(0);
  const [blogDetails, setBlogDetails] = useState();

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [placeholder, setPlaceholder] = useState();
  const config = useCallback(
    {
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      placeholder: placeholder || "Start typings...",
    },
    [placeholder]
  );

  const [openModal, setOpenModal] = useState(false);

  const cancelButtonRef = useRef(null);

  const [activeItem, setActiveItem] = useState({ type: "child", key: 0 });

  const currencies = [
    {
      name: "RON",
      value: "ron",
    },
    {
      name: "EUR",
      value: "eur",
    },
    {
      name: "USD",
      value: "usd",
    },
  ];

  const [currency, setCurrency] = useState("ron");

  const addBlog = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/create/blog/${id}`,
        {
          description: content,
        },
        {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((result) => {
        setData({ ...data, ["blogs"]: [...data.blogs, result.data] });
        setContent("");
      })
      .catch((err) => {});
  };

  const editBlog = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_API_URL}admin/update/blog/${blogDetails._id}`,
        {
          description: content,
        },
        {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((result) => {
        const index_obj = data.blogs.findIndex(
          (blog) => blog._id === result.data._id
        );
        const newBlogs = [...blogs];
        newBlogs[index_obj] = result.data;
        setData({ ...data, ["blogs"]: newBlogs });
      })
      .catch((err) => {});
  };

  if (isLoading) {
    return (<PropagateLoader/>)
  } else {
    return (
        <div className="min-h-screen w-full">
          <DashboardNavbar/>

          <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-30 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={setOpenModal}
            >
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-70"
                    leaveTo="opacity-0"
                >
                  <Dialog.Overlay
                      className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-gray-300 bg-opacity-60 transition-opacity"/>
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
              &#8203;
            </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div
                      className="inline-block align-bottom bg-white rounded-3xl md:rounded-4xl text-left overflow-hidden shadow-xl transform transition-all w-11/12 sm:w-9/12 sm:my-8 sm:align-middle md:max-w-2xl">
                    <div className="relative bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="w-full">
                        <button
                            onClick={() => {
                              setOpenModal(false);
                            }}
                            className="absolute top-3 right-3"
                        >
                          <svg
                              className="w-7 h-7"
                              viewBox="0 0 38 38"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M15.8333 22.1667L19 19L15.8333 22.1667ZM19 19L22.1667 15.8333L19 19ZM19 19L15.8333 15.8333L19 19ZM19 19L22.1667 22.1667L19 19ZM33.25 19C33.25 20.8713 32.8814 22.7243 32.1653 24.4532C31.4492 26.1821 30.3995 27.753 29.0763 29.0763C27.753 30.3995 26.1821 31.4492 24.4532 32.1653C22.7243 32.8814 20.8713 33.25 19 33.25C17.1287 33.25 15.2757 32.8814 13.5468 32.1653C11.8179 31.4492 10.247 30.3995 8.92373 29.0763C7.60049 27.753 6.55085 26.1821 5.83472 24.4532C5.11859 22.7243 4.75 20.8713 4.75 19C4.75 15.2207 6.25133 11.5961 8.92373 8.92373C11.5961 6.25134 15.2207 4.75 19 4.75C22.7793 4.75 26.4039 6.25134 29.0763 8.92373C31.7487 11.5961 33.25 15.2207 33.25 19Z"
                                stroke="#20484F"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        {isAuth() === undefined || null ? (
                            <div>
                              {
                                <div
                                    className="flex flex-col poppins-regular text-dark text-xs xs:text-sm lg:text-base mt-4 py-2 lg:mt-0 lg:py-8 sm:px-4 md:px-10 lg:px-14 xl:px-16">
                                  <h1 className="text-center text-darkgreen poppins-bold text-xl xs:text-2xl lg:text-3xl">
                                    Conectați-vă
                                  </h1>
                                  <h2 className="text-center text-darkgreen poppins-semi mt-2 sm:mt-4">
                                    Lorem Ipsum is simply dummy text of the printing
                                    and typesetting industry...
                                  </h2>
                                  <label className="mt-4 lg:mt-8">Email</label>
                                  <input
                                      type="email"
                                      id="email"
                                      placeholder="exemplu@gmail.com"
                                      className="{`input ${errors.email}`}  border-button placeholder-lightgray  rounded-2xl px-3 py-2.5 mt-4 focus:outline-none"
                                      name="email"
                                      onChange={handleChangeSignIn("email")}
                                      value={values.email}
                                      required
                                  />
                                  <div className="flex flex-row justify-between mt-6">
                                    <label className="">Parola</label>
                                    <h2>
                                      <Link
                                          to=""
                                          className="underline text-darkgreen"
                                      >
                                        Ați uitat parola?
                                      </Link>
                                    </h2>
                                  </div>
                                  <input
                                      type="password"
                                      id="password"
                                      placeholder="Introduceți parola"
                                      className="{`input ${errors.password}`} border-button placeholder-lightgray rounded-2xl px-3 py-2.5 mt-4 focus:outline-none"
                                      name="password"
                                      onChange={handleChangeSignIn("password")}
                                      value={values.password}
                                      required
                                  />
                                  <div className="flex flex-row items-center mt-5 checkbox-green">
                                    <input
                                        onChange={(e) => {
                                          setChecked(e.target.checked);
                                        }}
                                        className=" bg-darkgreen h-5 w-5"
                                        checked={checked}
                                        type="checkbox"
                                        id="rememberMe"
                                    />
                                    <label htmlFor="rememberMe"/>
                                    <span className="ml-2 rounded-xl items-center">
                                Ține minte pe acest dispozitiv
                              </span>
                                  </div>

                                  <button
                                      onClick={signInUser}
                                      className="poppins-regular text-white bg-darkgreen rounded-xl py-3 lg:px-9 mt-10"
                                  >
                                    Conectare
                                  </button>
                                  <h2 className="text-center mt-6 lg:mt-7">
                                    Nu aveți un cont?{" "}
                                    <Link
                                        to="/signup"
                                        className="underline text-darkgreen"
                                    >
                                      Înregistrare
                                    </Link>
                                  </h2>
                                </div>
                              }{" "}
                            </div>
                        ) : checkout === 0 ? (
                            <div className="mt-3 text-center sm:mt-0 sm:text-left px-2 md:px-10">
                              <div className="">
                                <div className="">
                                  <div className="flex flex-col w-full mt-5 xs:mt-8 m-auto">
                                    <div className="flex flex-row items-center bg-darkgreen rounded-3xl p-4">
                                      <img src={mastercard} alt="" className="w-14"/>
                                      <div className="ml-4 poppins-bold text-white text-sm space-y-2">
                                        <h1 className="text-left">
                                          Cardul meu de credit
                                        </h1>
                                        <h2 className="text-left">
                                          **** {creditCard()?.last4}
                                        </h2>
                                      </div>
                                    </div>

                                    <div className="flex flex-row mt-6">
                                      <svg
                                          className="hidden md:block w-6 h-6"
                                          viewBox="0 0 26 26"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                            d="M14.0833 17.3333H13V13H11.9167L14.0833 17.3333ZM13 8.66667H13.0108H13ZM22.75 13C22.75 14.2804 22.4978 15.5482 22.0078 16.7312C21.5178 17.9141 20.7997 18.9889 19.8943 19.8943C18.9889 20.7997 17.9141 21.5178 16.7312 22.0078C15.5482 22.4978 14.2804 22.75 13 22.75C11.7196 22.75 10.4518 22.4978 9.26884 22.0078C8.08591 21.5178 7.01108 20.7997 6.10571 19.8943C5.20034 18.9889 4.48216 17.9141 3.99217 16.7312C3.50219 15.5482 3.25 14.2804 3.25 13C3.25 10.4141 4.27723 7.93419 6.10571 6.10571C7.93419 4.27723 10.4141 3.25 13 3.25C15.5859 3.25 18.0658 4.27723 19.8943 6.10571C21.7228 7.93419 22.75 10.4141 22.75 13Z"
                                            stroke="#20484F"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                      </svg>
                                      <h3 className="text-left text-darkgreen poppins-semi text-sm md:ml-2">
                                        Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry.
                                      </h3>
                                    </div>

                                    <div className="flex flex-col mt-4">
                                      <div className="flex flex-row items-center poppins-bold text-darkgreen text-lg">
                                        <label className="flex flex-row ">
                                          Abonament:
                                        </label>
                                        <div className="flex flex-row flex-between items-center ml-2">
                                          <button
                                              onClick={decrementMonths}
                                              className="flex items-center rounded-full bg-white border border-darkgreen w-7 h-7"
                                          >
                                            <svg
                                                className="w-2.5 h-2.5 mx-auto"
                                                viewBox="0 0 12 3"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <line
                                                  x1="1.45605"
                                                  y1="1.73145"
                                                  x2="10.5443"
                                                  y2="1.73145"
                                                  stroke="#20484F"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                              />
                                            </svg>
                                          </button>
                                          <div className="relative mx-2.5 flex justify-center items-center">
                                            <h1 className="absolute poppins-semi px-2 text-sm xxs:text-base xs:text-lg md:text-xl lg:text-base">
                                              {months}
                                            </h1>
                                          </div>
                                          <button
                                              onClick={incrementMonths}
                                              className="rounded-full bg-darkgreen  border border-darkgreen w-7 h-7"
                                          >
                                            <svg
                                                className="w-3 h-3 mx-auto"
                                                viewBox="0 0 13 13"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                  d="M11.0855 6.5998H1.6335M6.35948 1.7998V11.3998V1.7998Z"
                                                  stroke="white"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                              />
                                            </svg>
                                          </button>
                                          <h1 className="poppins-semi ml-2.5 text-sm xxs:text-base xs:text-lg md:text-xl lg:text-base">
                                            luni
                                          </h1>
                                        </div>
                                      </div>
                                      <div className="flex flex-row items-center relative w-7/12 mt-3">
                                        <label className="poppins-bold text-darkgreen text-left text-lg">
                                          Moneda:
                                        </label>
                                        <div
                                            className="relative flex flex-row items-center bg-darkgreen py-1.5 rounded-xl ml-1.5">
                                          <select
                                              onChange={(e) => {
                                                setCurrency(e.target.value);
                                              }}
                                              className="relative block appearance-none font-bold text-white text-md mx-4 pr-5 bg-darkgreen rounded focus:outline-none"
                                              id="currency"
                                          >
                                            {Object.keys(currencies).map((u, i) => {
                                              return (
                                                  <option
                                                      selected={
                                                          currency === currencies[i]?.value
                                                      }
                                                      value={currencies[i]?.value}
                                                  >
                                                    {currencies[i]?.name}
                                                  </option>
                                              );
                                            })}
                                          </select>
                                          <div
                                              className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg
                                                className="fill-current h-5 w-5 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                              <path
                                                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" flex flex-col space-y-6 border-t mt-3 pt-3">
                                      <div className="flex flex-row justify-between">
                                        <h1 className="poppins-bold text-darkgreen text-lg">
                                          Total:
                                        </h1>
                                        <h1 className="poppins-bold text-darkgreen text-lg">
                                          {amount} {currency}
                                        </h1>
                                      </div>
                                      <div className="flex justify-end">
                                        <button
                                            onClick={donate}
                                            className="shadow-md bg-darkgreen rounded-2xl xxs:rounded-3xl poppins-bold text-white text-sm xxs:text-base xs:text-lg py-3 px-5 xxs:py-3.5 xxs:px-8 xs:px-9 "
                                        >
                                          Doneaza
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        ) : (
                            <div className="w-full">{PaymentForm()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <div
              className="2xl:container 2xl:mx-auto py-2 sm:py-4 xl:py-8 px-3  xxs:px-4 xs:px-6 sm:px-12 lg:px-20  xl:px-24">
            <div className="flex flex-col space-y-10 lg:space-y-0 lg:flex-row justify-between py-6 md:py-10">
              {/*user*/}
              <div className="flex flex-col lg:w-1/3 lg:mr-10">
                <button
                    onClick={() => {
                      setCurrentItem(0);
                      setActiveItem({type: "child", key: 0});
                    }}
                    className={`flex flex-row items-center ${
                        activeItem.type === "child"
                            ? "bg-green border-2 border-darkgreen text-white"
                            : "border-1.5 border-gray bg-white"
                    }  rounded-3xl md:rounded-4xl lg:rounded-3xl h-16 xs:h-20 md:h-22 lg:h-20`}
                >
                  <img
                      src={img}
                      alt=""
                      className="rounded-full w-24 xs:w-26 md:w-32 lg:w-24 -ml-1.5 md:-ml-2 "
                  />
                  <div className="flex flex-col text-left ml-4">
                    <h1 className="poppins-semi text-darkgreen text-base xxs:text-lg xs:text-xl md:text-2xl lg:text-base">
                      Detalii despre
                    </h1>

                    <h1 className="poppins-bold text-darkgreen -mt-1 xxs:mt-0 xs:-mt-1 lg:mt-0 text-base xxs:text-lg xs:text-xl md:text-2xl lg:text-base">
                      Andrei
                    </h1>
                  </div>
                </button>

                {/*details list*/}
                <div className="mt-5 md:mt-8 lg:mt-6 flex flex-col gap-2 sm:grid sm:grid-cols-2 lg:grid-cols-1">
                  {isAuth()?.type === "admin" ? (
                      <button
                          onClick={() => {
                            setContent("");
                            setCurrentItem(2);
                            setActiveItem({type: "addblog"});
                          }}
                          className={`${
                              activeItem.type === "addblog"
                                  ? "bg-green border-2 border-darkgreen text-white"
                                  : "border-1.5 border-gray bg-white"
                          } w-full  rounded-2xl xs:rounded-3xl py-4 xxs:py-5 xl:py-5.5`}
                      >
                        <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 m-auto"
                        >
                          <path
                              d="M20.3334 10.9998H1.66675M11.0001 1.6665V20.3332V1.6665Z"
                              stroke="#20484F"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                  ) : null}
                  {Object.keys(data.blogs).map((u, i) => {
                    return (
                        <button
                            key={i}
                            onClick={() => {
                              setContent(data.blogs[i].description);
                              setBlogDetails(data.blogs[i]);
                              setCurrentItem(1);
                              setActiveItem({type: "blog", key: i});
                            }}
                            className={`text-left ${
                                activeItem.type === "blog" && activeItem.key === i
                                    ? "bg-green border-2 border-darkgreen text-white"
                                    : "border-1.5 border-gray bg-white"
                            } rounded-2xl xs:rounded-3xl w-full py-2.5 px-4`}
                        >
                          <h1 className="poppins-bold  text-darkgreen text-sm xxs:text-base xs:text-lg md:text-xl lg:text-base">

                          </h1>
                          <h2
                              dangerouslySetInnerHTML={{
                                __html: data.blogs[i].description,
                              }}
                              className="line-clamp-2 poppins-regular text-darkgreen text-xs xxs:text-sm xs:text-base md:text-lg lg:text-sm"
                          ></h2>
                        </button>
                    );
                  })}
                </div>
              </div>

              {/*details*/}
              {currentItem === 0 ? (
                  <div className="relative mt-10 md:mt-14 lg:mt-0 md:flex flex-row justify-between lg:w-3/4">
                    <div className="flex flex-col relative w-full md:w-1/3 lg:w-4/12 md:space-y-0">
                      <div
                          className="absolute poppins-900 w-1/5 xxs:w-1/6 sm:w-2/12 xl:w-1/5 top-3 right-4 text-sm xs:text-base">
                        <CircularProgressbar
                            value={75}
                            text={"75%"}
                            strokeWidth={11}
                            background
                            backgroundPadding={8}
                            className=" md:hidden"
                            styles={buildStyles({
                              backgroundColor: "#fff",
                              textColor: "#20484F",
                              pathColor: "#20484F",
                              trailColor: "transparent",
                            })}
                        />
                      </div>

                      <img
                          src={img2}
                          alt=""
                          className="details-image rounded-4xl object-cover sm:h-64 md:h-48 lg:h-36 xl:h-44 2xl:h-50  md:m-auto"
                      />
                      <div
                          className="flex flex-row justify-evenly sm:space-x-1 md:space-x-0 p-3 xxs:pt-4 sm:pt-6 lg:px-0 md:grid md:grid-cols-2 md:gap-4 lg:gap-2 md:w-11/12 md:m-auto justify-items-center">
                        {Object.keys(data?.gallery).map((u, i) => {
                          return (
                              <ImageViewer>
                                <img
                                    src={data?.gallery[i]}
                                    alt="Img"
                                    className="w-16 h-16 xxs:w-18 xxs:h-18 xs:w-22 xs:h-22 lg:w-16 lg:h-16 rounded-2xl"
                                />
                              </ImageViewer>
                          );
                        })}
                      </div>
                    </div>
                    <div className="relative md:flex flex-col md:w-2/3 lg:w-3/4 md:px-6 lg:px-0 lg:ml-6">
                      <div className="absolute font-extrabold w-22 lg:w-20 top-2 right-10 text-sm xs:text-base">
                        <CircularProgressbar
                            value={75}
                            text={"75%"}
                            strokeWidth={9}
                            background
                            backgroundPadding={8}
                            className="hidden md:block"
                            styles={buildStyles({
                              backgroundColor: "#20484F",
                              textColor: "#fff",
                              pathColor: "#fff",
                              trailColor: "transparent",
                            })}
                        />
                      </div>
                      <h1 className="poppins-bold text-darkgreen mt-2 sm:mt-6 md:mt-4 text-base xxs:text-lg xs:text-xl md:text-2xl lg:text-lg">
                        {data?.title}
                      </h1>
                      <div
                          className="flex flex-row items-center mt-3 lg:mt-5 space-x-5 xs:space-x-12 sm:space-x-20 md:space-x-12 xl:space-x-6 2xl:space-x-4 text-xs xxs:text-sm  xs:text-base md:text-lg lg:text-sm">
                        <div className="flex flex-row items-center">
                          <img
                              src={calendar}
                              alt=""
                              className="w-5 xs:w-6 sm:w-7 lg:w-6"
                          />
                          <h3 className="poppins-bold text-darkgreen ml-2 sm:ml-1 md:ml-2 xl:ml-1 xs:mt-1 xl:mt-0">
                            {data?.date}
                          </h3>
                        </div>
                        <div className="flex flex-row items-center">
                          <img
                              src={card}
                              alt=""
                              className="w-5 xs:w-7 sm:w-8 lg:w-7"
                          />
                          <h3 className="poppins-bold text-darkgreen ml-2">
                            {data?.total_amount} lei
                          </h3>
                        </div>
                      </div>
                      <p dangerouslySetInnerHTML={{__html: data.description}}
                         className="poppins-semi text-darkgreen mt-5 text-sm xxs:text-base xs:text-lg md:text-xl lg:text-base">

                      </p>
                      {isAuth()?.type !== "admin" ? (
                          <div className="flex flex-col mt-10 xs:mt-16 md:mt-8">
                            <div className="flex flex-row justify-center space-x-4">
                              <button
                                  onClick={() => {
                                    setAmount(10);
                                  }}
                                  className="bg-darkgreen text-white py-2.5 px-5 rounded-3xl md:rounded-4xl poppins-bold text-sm xxs:text-base xs:text-lg md:text-lg lg:text-base"
                              >
                                <h1>10 {currency}</h1>
                              </button>
                              <button
                                  onClick={() => {
                                    setAmount(30);
                                  }}
                                  className="bg-darkgreen text-white py-2.5 px-5 rounded-3xl md:rounded-4xl poppins-bold text-sm xxs:text-base xs:text-lg md:text-lg lg:text-base"
                              >
                                <h1>30 {currency}</h1>
                              </button>
                              <button
                                  onClick={() => {
                                    setAmount(50);
                                  }}
                                  className="bg-darkgreen text-white py-2.5 px-5 rounded-3xl md:rounded-4xl poppins-bold text-sm xxs:text-base xs:text-lg md:text-lg lg:text-base"
                              >
                                <h1>50 {currency}</h1>
                              </button>
                            </div>
                            <div className="flex flex-row items-center justify-between mt-3 xxs:mt-6">
                              <svg
                                  width="150"
                                  height="3"
                                  viewBox="0 0 150 3"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-1/3"
                              >
                                <line
                                    opacity="0.2"
                                    x1="0.000521195"
                                    y1="1.25"
                                    x2="150"
                                    y2="1.35424"
                                    stroke="#C5C5C5"
                                    strokeWidth="1.5"
                                />
                              </svg>
                              <h1 className="poppins-bold text-lightgray text-xs xxs:text-sm xs:text-base md:text-lg lg:text-sm">
                                sau
                              </h1>
                              <svg
                                  width="150"
                                  height="3"
                                  viewBox="0 0 150 3"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-1/3"
                              >
                                <line
                                    opacity="0.2"
                                    x1="0.000521195"
                                    y1="1.25"
                                    x2="150"
                                    y2="1.35424"
                                    stroke="#C5C5C5"
                                    strokeWidth="1.5"
                                />
                              </svg>
                            </div>
                            <div className="flex flex-row items-center justify-between sm:justify-around mt-4">
                              <div className="w-64 mt-1 relative">
                                <div>
                                  <input
                                      type="number"
                                      onChange={handleAmount}
                                      id="suma"
                                      value={amount}
                                      placeholder="ex: 20"
                                      className="w-full text-darkgreen placeholder:text-gray-50 rounded-2xl xxs:rounded-2xl md:rounded-2xl lg:rounded-2xl border border-lightgray poppins-medium text-sm xxs:text-base md:text-lg lg:text-base py-2.5 px-3 outline-none"
                                  />
                                  <div
                                      className="absolute inset-y-0 right-0 flex items-center bg-darkgreen rounded-2xl">
                                    <div className="relative flex flex-row items-center ml-1.5">
                                      <select
                                          onChange={(e) => {
                                            setCurrency(e.target.value);
                                          }}
                                          className="relative block appearance-none font-bold text-white text-md mx-4 pr-5 bg-darkgreen focus:outline-none"
                                          id="currency"
                                      >
                                        {Object.keys(currencies).map((u, i) => {
                                          return (
                                              <option
                                                  key={i}
                                                  selected={
                                                      currency === currencies[i]?.value
                                                  }
                                                  value={currencies[i]?.value}
                                              >
                                                {currencies[i]?.name}
                                              </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div
                                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                      <svg
                                          className="fill-current h-5 w-5 text-white"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                      >
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <svg
                                  width="35"
                                  height="40"
                                  viewBox="0 0 35 40"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-6"
                              >
                                <line
                                    opacity="0.2"
                                    x1="1.42777"
                                    y1="38.6641"
                                    x2="33.749"
                                    y2="0.515207"
                                    stroke="#C7C8D2"
                                    strokeWidth="1.5"
                                />
                              </svg>

                              <div className="flex flex-row flex-between items-center ml-2">
                                <button
                                    onClick={decrementMonths}
                                    className="flex items-center rounded-full bg-white border border-darkgreen w-8 h-8"
                                >
                                  <svg
                                      className="w-3 h-3 mx-auto"
                                      viewBox="0 0 12 3"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <line
                                        x1="1.45605"
                                        y1="1.73145"
                                        x2="10.5443"
                                        y2="1.73145"
                                        stroke="#20484F"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                  </svg>
                                </button>
                                <div className="relative mx-2.5 flex justify-center items-center">
                                  <h1 className="absolute poppins-semi px-2 text-sm xxs:text-base xs:text-lg md:text-xl lg:text-base">
                                    {months}
                                  </h1>
                                </div>
                                <button
                                    onClick={incrementMonths}
                                    className="rounded-full bg-darkgreen  border border-darkgreen w-8 h-8"
                                >
                                  <svg
                                      className="w-3 h-3 mx-auto"
                                      viewBox="0 0 13 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                        d="M11.0855 6.5998H1.6335M6.35948 1.7998V11.3998V1.7998Z"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                                <h1 className="poppins-semi ml-2.5 text-sm xxs:text-base xs:text-lg md:text-xl lg:text-base">
                                  luni
                                </h1>
                              </div>
                            </div>
                            {error && !amount ? (
                                <h1
                                    className={`poppins-regular text-error text-sm xxs:text-base xs:text-lg md:text-xl lg:text-base mt-2 md:mt-3`}
                                >
                                  {" "}
                                  Introduceți o sumă
                                </h1>
                            ) : null}

                            <div className="mx-auto mt-10 xs:mt-16 md:mt-14 lg:mt-10">
                              <button
                                  onClick={() => {
                                    if (!amount) {
                                      setOpenModal(false);
                                      setError(true);
                                    } else {
                                      setOpenModal(true);
                                    }
                                  }}
                                  className="poppins-bold text-white bg-darkgreen text-xl xs:text-xl lg:text-lg py-3 px-6 rounded-3xl xs:rounded-3xl lg:rounded-3xl"
                              >
                                Donează
                              </button>
                            </div>
                          </div>
                      ) : null}
                    </div>
                  </div>
              ) : isAuth()?.type === "admin" ? (
                  <div className={`w-full lg:w-3/4`}>
                    <JoditEditor
                        ref={editor}
                        value={content}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {
                        }}
                    />
                    <div className="w-full flex place-content-end mt-4 xxs:mt-10 xs:mt-12 xl:mt-16 py-3 px-3">
                      {currentItem === 2 ? (
                          <button
                              onClick={addBlog}
                              className="shadow-md bg-darkgreen py-2.5 lg:py-2  px-5  rounded-2xl nunito-bold text-white text-base xs:text-lg lg:text-base"
                          >
                            Adaugă blog
                          </button>
                      ) : (
                          <button
                              onClick={editBlog}
                              className="shadow-md bg-darkgreen py-2.5 lg:py-2  px-5  rounded-2xl nunito-bold text-white text-base xs:text-lg lg:text-base"
                          >
                            Salvează
                          </button>
                      )}
                    </div>
                  </div>
              ) : (
                  <div
                      className="mt-10 md:mt-14 lg:mt-0 md:flex flex-row justify-between lg:w-3/4 text-darkgreen relative">
                    <div className="w-full flex flex-col ">
                      <h1 className="poppins-bold mt-2 text-base xxs:text-lg xs:text-xl md:text-2xl">
                        {blogDetails?.title}
                      </h1>
                      <p
                          dangerouslySetInnerHTML={{__html: content}}
                          className="poppins-semi mt-6 text-sm xxs:text-base xs:text-lg md:text-xl"
                      ></p>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
    );
  }
}
