import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import {isAuth} from "./helpers/auth";

function Navbar() {
    const navigate = useNavigate();

    function Logout() {
        localStorage.clear();
        navigate("/");
    }

    const useModal = () => {
        const [isShowing, setIsShowing] = useState(false);

        function toggle() {
            setIsShowing(!isShowing);
        }

        return {
            isShowing,
            toggle,
        }
    };

    const {isShowing, toggle} = useModal();

    const Modal = ({isShowing, hide}) => isShowing ? (

        <React.Fragment>
            <div
                className=" z-100 md:hidden fixed inset-0 backdrop-filter backdrop-blur-xs bg-gray-300 bg-opacity-60 transition-opacity"
                onClick={hide}
            >
                <div onClick={e => {
                    e.stopPropagation();
                }} className="modal md:hidden absolute px-6 sm:px-8 py-4 top-0 left-0 h-screen w-2/3 bg-darkgreen">
                    <div className="modal-header flex flex-row place-content-end">
                        <button type="button"
                                className="absolute float-right right-8 mt-4 sm:mt-8 modal-close-button bg-green p-2.5 sm:p-3 rounded-full "
                                data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <svg className={`sm:w-5 sm:h-5`} width="13" height="13" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.99999 5.58599L11.95 0.635986L13.364 2.04999L8.41399 6.99999L13.364 11.95L11.95 13.364L6.99999 8.41399L2.04999 13.364L0.635986 11.95L5.58599 6.99999L0.635986 2.04999L2.04999 0.635986L6.99999 5.58599Z"
                                    fill="#20484F"/>
                            </svg>
                        </button>
                    </div>
                    <nav className="flex mt-8">
                        <ul className=" flex flex-col space-y-8 mt-12 ml-6 sm:ml-10 poppins-semi text-md sm:text-lg text-primarylight ">
                            <Link to="/">
                                <li>ACASĂ</li>
                            </Link>
                            <li>DESPRE NOI</li>
                            {!isAuth() ? <Link to="/signin">
                                    <li>LOGARE</li>
                                </Link> :
                                <Link to="/dashboard/newsfeed">
                                    <li>DASHBOARD</li>
                                </Link>}
                            {!isAuth() ?
                                <Link to="/signup">
                                    <li>ÎNREGISTRARE</li>
                                </Link> :
                                <li onClick={Logout}>DELOGARE</li>
                            }
                        </ul>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    ) : null;

    return (
        <div className=" z-10 w-full bg-darkgreen">
            <div
                className=" 2xl:container 2xl:mx-auto flex flex-row md:justify-between  px-6 py-5 xxs:px-8 xs:py-6 sm:px-12 lg:px-20 xl:px-24 lg:py-5 xl:py-4">
                <div className="flex flex-row items-center">
                    <button onClick={toggle}>
                        <svg className="md:hidden w-6 xxs:w-6 xs:w-7" viewBox="0 0 27 21"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.9583 19.25H25.1666M1.83331 1.75H25.1666H1.83331ZM1.83331 10.5H25.1666H1.83331Z"
                                  stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>

                    <Modal
                        isShowing={isShowing}
                        hide={toggle}
                    />

                    <Link to="/">
                        <div
                            className="ml-4 md:ml-0 text-white poppins-bold mt-1 text-xl xs:text-2xl sm:text-3xl md:text-2xl xl:text-3xl">
                            Aimila.
                        </div>
                    </Link>
                </div>

                <nav className="hidden md:flex text-base xl:text-lg">
                    <ul className=" flex flex-row poppins-regular items-center">
                        <Link to="/">
                            <li className="text-white md:px-3 xl:px-4 ">Acasa</li>
                        </Link>
                        <li className="text-white md:px-3 xl:px-4 ">Despre noi</li>
                        {!isAuth() ? <Link to="/signin">
                                <li className="text-white md:px-3.5 md:mr-2 xl:px-4 ">Logare</li>
                            </Link> :
                            <Link to="/dashboard/newsfeed">
                                <li className="text-white md:px-3.5 md:mr-2 xl:px-4 ">Dashboard</li>
                            </Link>}
                        {!isAuth() ?
                            <Link to="/signup">
                                <button
                                    className="text-darkgreen bg-primarygreen rounded-2xl md:py-1.5 xl:py-2.5 md:px-3 xl:px-4 ">
                                    <h1>Înregistrare</h1>
                                </button>
                            </Link> :
                            <button onClick={Logout}
                                    className="text-darkgreen bg-primarygreen rounded-xl xl:rounded-2xl  md:py-1.5 xl:py-2.5 md:px-3 xl:px-4 ">
                                <h1>Delogare</h1>
                            </button>
                        }
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Navbar;
