export default function validate(values) {
  let errors = {};
  if (!values.email) {
    errors.email = "Introduceți email-ul";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email invalid";
  }

  if (!values.username) {
    errors.username = "Introduceți numele";
  }

  if (!values.password) {
    errors.password = "Introduceți parola";
  }

  return errors;
}
