import React, { useState } from "react";
import decorationTopLeft from "../images/DecorationTopLeft.svg";
import decorationBottomRight from "../images/DecorationBottomRight.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useForm from "./useForm";
import validate from "./LoginFormValidationRules";
import axios from "axios";
import { isAuth } from "../helpers/auth";

const SignInForm = () => {
  const [errorMessage, setErrorMessage] = useState('');
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let query_email = query.get("email");

  const { values, errors } = useForm(login, validate);
  const navigate = useNavigate();

  function login() {}

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const signInUser = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}auth/login`, {
        email,
        password,
      })
      .then((response) => {

        const { email, name, token, type } = response?.data;
        localStorage.setItem(
          "user",
          JSON.stringify({ email, name, token, type })
        );
        if (response.data?.card) {
          localStorage.setItem("card", JSON.stringify(response.data?.card));
        }

        isAuth() && isAuth().type === "superAdmin"
         ? navigate("/super-admin/dashboard")
         : navigate("/dashboard/newsfeed");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          setErrorMessage({message: 'Parolă incorectă!', display: true});
        } else {
          setErrorMessage({message: 'Utilizatorul nu a fost găsit!', display: true});
        }
      });
  };
  const handleChangeSignIn = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value });
  };

  const [checked, setChecked] = useState(false);

  return (
    <div className="relative bg-primarylight py-14 xs:py-18 lg:py-24">
      <img
        src={decorationTopLeft}
        alt=""
        className="hidden sm:block absolute top-0 left-0 sm:w-32 lg:w-40 xl:w-50"
      />
      <img
        src={decorationBottomRight}
        alt=""
        className="hidden sm:block absolute bottom-0 right-0 sm:w-32 lg:w-40 xl:w-50"
      />
      <div className="2xl:container 2xl:mx-auto relative">
        <div className="flex flex-col bg-white rounded-4xl m-auto w-10/12 sm:w-8/12 md:w-3/6 lg:w-2/5 xl:w-2/5 px-4 md:px-6 lg:px-10  py-7 lg:py-8 xl:py-12">
          <h1 className="mb-2 lg:mb-4 text-center poppins-bold text-darkgreen text-xl md:text-2xl lg:text-3xl">
            Conectați-vă
          </h1>
          {window.location.href.indexOf("email") > -1 &&
          window.location.href.indexOf("success=true") > -1 ? (
            <h1 className="poppins-regular text-success text-center text-xs xs:text-sm sm:text-base">
              A fost trimis un email la adresa {query_email}{" "}
            </h1>
          ) : window.location.href.indexOf("activate") > -1 &&
            window.location.href.indexOf("success=true") > -1 ? (
            <h1 className="poppins-regular text-success text-center text-xs xs:text-sm sm:text-base">
              Contul a fost activat{" "}
            </h1>
          ) : window.location.href.indexOf("email") > -1 &&
            window.location.href.indexOf("success=false") > -1 ? (
            <h1 className="poppins-regular text-error text-center text-xs xs:text-sm sm:text-base">
              A apărut o eroare.{" "}
            </h1>
          ) : window.location.href.indexOf("activate") > -1 &&
            window.location.href.indexOf("success=false") > -1 ? (
            <h1 className="poppins-regular text-error text-center text-xs xs:text-sm sm:text-base">
              A apărut o eroare.{" "}
            </h1>
          ) : null}
          {errorMessage.display && (<div className={`w-4/5 m-auto flex flex-row justify-between items-center space-x-2 py-2 px-6 rounded-xl bg-error`}>
                <div className={`flex flex-row space-x-2 items-center`}> <svg width="19" height="17" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 7.625V9.54167V7.625ZM10.5 13.375H10.5096H10.5ZM3.86066 17.2083H17.1393C18.6152 17.2083 19.5371 15.6108 18.7992 14.3333L12.1598 2.83334C11.4219 1.55588 9.57807 1.55588 8.84016 2.83334L2.20082 14.3333C1.46291 15.6108 2.38482 17.2083 3.86066 17.2083Z" stroke="#EB2222" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                  <p className="error text-sm poppins-semi text-rederror">{errorMessage.message} </p>
                </div>
                <button
                    onClick={() => {
                      setErrorMessage({message: errorMessage.message, display: false})}
                    }>
                  <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 1.5L12.5 12.5M1.5 12.5L12.5 1.5L1.5 12.5Z" stroke="#EB2222" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
          )}


          <form
            onSubmit={signInUser}
            className="flex flex-col poppins-regular text-dark text-xs xs:text-sm lg:text-base"
          >
            <label className="mt-2 lg:mt-4">Email</label>
            <input
              type="email"
              id="email"
              placeholder="exemplu@gmail.com"
              className="{`input ${errors.email}`}  border-button placeholder-lightgray  rounded-2xl px-3 py-2.5 mt-2 focus:outline-none"
              name="email"
              onChange={handleChangeSignIn("email")}
              value={values.email}
              required
            />
            <div className="flex flex-row justify-between mt-4">
              <label className="">Parola</label>
              <h2>
                <Link
                  to="/forgot/password"
                  className="underline text-darkgreen"
                >
                  Ați uitat parola?
                </Link>
              </h2>
            </div>
            <input
              type="password"
              id="password"
              placeholder="Introduceți parola"
              className="{`input ${errors.password}`} border-button placeholder-lightgray  rounded-2xl px-3 py-2.5 mt-2 focus:outline-none"
              name="password"
              onChange={handleChangeSignIn("password")}
              value={values.password}
              required
            />
            <div className="flex flex-row items-center mt-5 checkbox-green">
              <input
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
                className="bg-darkgreen h-5 w-5"
                checked={checked}
                type="checkbox"
                id="rememberMe"
              />
              <label htmlFor="rememberMe" />
              <span className="ml-2 rounded-xl  items-center">
                Ține minte pe acest dispozitiv
              </span>
            </div>

            <button
              type="submit"
              className="poppins-regular text-white bg-darkgreen rounded-xl py-3 lg:px-9 mt-5"
            >
              Conectare
            </button>
            <h2 className="text-center mt-4 lg:mt-5">
              Nu aveți un cont?{" "}
              <Link to="/signup" className="underline text-darkgreen">
                Înregistrare
              </Link>
            </h2>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
