import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";


function SuperAdminNavbar() {
    const navigate = useNavigate();

    function Logout() {
        localStorage.clear();
        navigate("/");
    }

    const useModal = () => {
        const [isShowing, setIsShowing] = useState(false);

        function toggle() {
            setIsShowing(!isShowing);
        }

        return {
            isShowing,
            toggle,
        }
    };

    const {isShowing, toggle} = useModal();

    const Modal = ({isShowing, hide}) => isShowing ? (

        <React.Fragment>
            <div
                className=" z-100 md:hidden fixed inset-0 backdrop-filter backdrop-blur-xs bg-gray-300 bg-opacity-60 transition-opacity"
                onClick={hide}
            >
                <div onClick={e => {
                    e.stopPropagation();
                }} className="modal md:hidden absolute px-6 sm:px-8 py-4 top-0 left-0 h-screen w-2/3 bg-darkgreen">
                    <div className="modal-header flex flex-row place-content-end">
                        <button type="button"
                                className="absolute float-right right-8 mt-4 sm:mt-8 modal-close-button bg-green p-2.5 sm:p-3 rounded-full "
                                data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <svg className={`sm:w-5 sm:h-5`} width="13" height="13" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.99999 5.58599L11.95 0.635986L13.364 2.04999L8.41399 6.99999L13.364 11.95L11.95 13.364L6.99999 8.41399L2.04999 13.364L0.635986 11.95L5.58599 6.99999L0.635986 2.04999L2.04999 0.635986L6.99999 5.58599Z"
                                    fill="#20484F"/>
                            </svg>
                        </button>
                    </div>
                    <nav className="flex mt-8">
                        <ul className="w-full flex flex-col  mt-16 sm:mt-20  poppins-semi text-md xxs:text-lg sm:text-2xl text-white ">
                            <Link to="/super-admin/dashboard">
                                <li className={`xxs:ml-2 sm:ml-6 flex items-center space-x-2.5 xxs:space-x-3.5`}><svg  className="w-6 h-7" width="22" height="20" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M29.4965 12.6565L24.9498 8.10982V2.85768C24.9498 1.90802 24.1803 1.13839 23.2292 1.13839C22.2804 1.13839 21.5108 1.90802 21.5108 2.85768V4.67083L18.1255 1.28536C16.4517 -0.387473 13.5416 -0.384506 11.8717 1.28839L0.503384 12.6565C-0.167713 13.3289 -0.167713 14.4169 0.503384 15.0883C1.17478 15.7606 2.26494 15.7606 2.93609 15.0883L14.3033 3.71995C14.6736 3.35158 15.3263 3.35158 15.6947 3.71886L27.0638 15.0883C27.401 15.4245 27.8405 15.5917 28.2799 15.5917C28.7202 15.5917 29.1605 15.4243 29.4966 15.0883C30.1679 14.417 30.1679 13.329 29.4965 12.6565Z" fill="white"/>
                                    <path d="M15.5972 6.98341C15.267 6.65332 14.7322 6.65332 14.4029 6.98341L4.40326 16.98C4.24539 17.1379 4.15588 17.3533 4.15588 17.5782V24.8693C4.15588 26.5803 5.54313 27.9674 7.254 27.9674H12.2048V20.3003H17.7942V27.9674H22.7451C24.4559 27.9674 25.8431 26.5803 25.8431 24.8694V17.5782C25.8431 17.3533 25.7544 17.1379 25.5957 16.98L15.5972 6.98341Z" fill="white"/>
                                </svg><h1>Dashboard</h1></li>
                            </Link>
                            <Link to="/super-admin/newsfeed">
                                <li className={`xxs:ml-2 sm:ml-6 flex items-center mt-6 space-x-3 xxs:space-x-4 `}><svg className="w-6 h-7" width="18" height="22" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.25 19.25V16.75V19.25ZM11 19.25V14.25V19.25ZM14.75 19.25V11.75V19.25ZM17.25 24.25H4.75C4.08696 24.25 3.45107 23.9866 2.98223 23.5178C2.51339 23.0489 2.25 22.413 2.25 21.75V4.25C2.25 3.58696 2.51339 2.95107 2.98223 2.48223C3.45107 2.01339 4.08696 1.75 4.75 1.75H11.7325C12.064 1.75007 12.3819 1.88181 12.6163 2.11625L19.3838 8.88375C19.6182 9.11812 19.7499 9.43601 19.75 9.7675V21.75C19.75 22.413 19.4866 23.0489 19.0178 23.5178C18.5489 23.9866 17.913 24.25 17.25 24.25Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                    <h1>Cazuri</h1></li>
                            </Link>

                                <button onClick={Logout} className={`poppins-bold bg-lightred text-center m-auto mt-24 px-10 py-2 xxs:px-12 xxs:py-3.5 sm:px-18 sm:py-3 rounded-2xl xxs:rounded-3xl`}>Logout</button>
                        </ul>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    ) : null;

    return (
<div className={`relative md:w-1/3 md:min-h-full lg:w-1/4 2xl:w-1/5 3xl:w-1/6  bg-darkgreen `}>
        <div className=" z-10 w-full h-full bg-darkgreen  ">
            <div
                className="md:h-screen  px-6 py-5 xxs:px-8 xs:py-6 sm:px-12 md:px-8 md:py-8">
                <div className="md:h-full flex md:flex-col md:justify-between ">
                    {/*   */}
                    <div className={`flex md:flex-col`}>
                        <button onClick={toggle}>
                            <svg className="md:hidden w-6 xxs:w-6 xs:w-7" viewBox="0 0 27 21"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9583 19.25H25.1666M1.83331 1.75H25.1666H1.83331ZM1.83331 10.5H25.1666H1.83331Z"
                                      stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>

                        <Modal
                            isShowing={isShowing}
                            hide={toggle}
                        />
                    <div
                        className="ml-4 sm:ml-6 md:ml-0 text-white poppins-bold mt-1 text-xl xs:text-2xl sm:text-3xl">
                        Aimila.
                    </div>
                    <ul className={`hidden md:block w-full flex flex-col mt-20  poppins-semi  text-white text-xl`}>

                        <Link to="/super-admin/dashboard">
                        <li className={` flex items-center space-x-2.5 xxs:space-x-3.5`}><svg  className="w-6 h-7" width="22" height="20" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.4965 12.6565L24.9498 8.10982V2.85768C24.9498 1.90802 24.1803 1.13839 23.2292 1.13839C22.2804 1.13839 21.5108 1.90802 21.5108 2.85768V4.67083L18.1255 1.28536C16.4517 -0.387473 13.5416 -0.384506 11.8717 1.28839L0.503384 12.6565C-0.167713 13.3289 -0.167713 14.4169 0.503384 15.0883C1.17478 15.7606 2.26494 15.7606 2.93609 15.0883L14.3033 3.71995C14.6736 3.35158 15.3263 3.35158 15.6947 3.71886L27.0638 15.0883C27.401 15.4245 27.8405 15.5917 28.2799 15.5917C28.7202 15.5917 29.1605 15.4243 29.4966 15.0883C30.1679 14.417 30.1679 13.329 29.4965 12.6565Z" fill="white"/>
                            <path d="M15.5972 6.98341C15.267 6.65332 14.7322 6.65332 14.4029 6.98341L4.40326 16.98C4.24539 17.1379 4.15588 17.3533 4.15588 17.5782V24.8693C4.15588 26.5803 5.54313 27.9674 7.254 27.9674H12.2048V20.3003H17.7942V27.9674H22.7451C24.4559 27.9674 25.8431 26.5803 25.8431 24.8694V17.5782C25.8431 17.3533 25.7544 17.1379 25.5957 16.98L15.5972 6.98341Z" fill="white"/>
                        </svg><h1>Dashboard</h1></li>
                        </Link>
                        <Link to="/super-admin/newsfeed">
                        <li className={`flex items-center space-x-2.5 xxs:space-x-3.5 mt-8`}><svg className="w-6 h-7" width="18" height="22" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.25 19.25V16.75V19.25ZM11 19.25V14.25V19.25ZM14.75 19.25V11.75V19.25ZM17.25 24.25H4.75C4.08696 24.25 3.45107 23.9866 2.98223 23.5178C2.51339 23.0489 2.25 22.413 2.25 21.75V4.25C2.25 3.58696 2.51339 2.95107 2.98223 2.48223C3.45107 2.01339 4.08696 1.75 4.75 1.75H11.7325C12.064 1.75007 12.3819 1.88181 12.6163 2.11625L19.3838 8.88375C19.6182 9.11812 19.7499 9.43601 19.75 9.7675V21.75C19.75 22.413 19.4866 23.0489 19.0178 23.5178C18.5489 23.9866 17.913 24.25 17.25 24.25Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg><h1>Cazuri</h1></li>
                        </Link>
                    </ul>
                    </div>


                       </div>


            </div>
        </div>
    <div className={`relative inset-x-0`}>
    <div className={`absolute fixed flex justify-center mx-auto bottom-8`}>
        <button onClick={Logout} className={` hidden md:block poppins-bold bg-lightred text-center text-white px-18 py-3.5 rounded-3xl`}>Logout</button>
    </div></div>

</div>
    );
}

export default SuperAdminNavbar;
