import React from "react";
import Navbar from "./Navbar";
import SignInForm from "./forms/SignInForm";
import Footer from "./Footer";
import {isAuth} from "./helpers/auth";
import {Navigate} from "react-router-dom";

function SignIn() {
    return (
        isAuth() ? <Navigate to="/dashboard/newsfeed"/> : <div>
            <Navbar/>
            <SignInForm/>
            <Footer/>
        </div>
    );
}

export default SignIn;
