import React from "react";
import icon1 from "../images/Frame 20.svg";
import icon2 from "../images/Frame 21.svg";
import icon3 from "../images/Frame 22.svg";
import icon4 from "../images/Frame 23.svg";
import divider from "../images/divider.svg";

function Section3() {
    return (
        <div className="bg-palegrey relative">
            <div className="2xl:container 2xl:mx-auto relative ">
                <div
                    className="relative bg-palegrey h-min px-6 pt-12 pb-12 xxs:py-16 xxs:px-8 xs:py-24 sm:py-28 md:px-12 md:py-36 lg:px-20 lg:py-44 xl:py-56 2xl:px-0">
                    <h1 className="poppins-bold text-dark text-2xl xs:text-3xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-5xl">
                        Lorem Ipsum
                    </h1>
                    <h2 className="poppins-regular text-dark mt-4 md:mt-2 text-sm xs:text-base sm:text-lg md:text-base lg:text-lg xl:text-xl">
                        Lorem Ipsum is simply dummy text of the printing
                    </h2>

                    <div
                        className="grid grid-cols-2 grid-rows-2 gap-x-5 gap-y-10 xs:gap-x-8 sm:gap-14 md:gap-x-10 xl:gap-x-9  md:flex md:flex-row mt-9 sm:mt-14 lg:mt-14">
                        {" "}
                        <div>
                            <img src={icon1} alt="" className="w-9 xxs:w-12 sm:w-16 md:w-14"/>
                            <h3 className=" poppins-bold text-dark mt-6 text-sm xs:text-base sm:text-lg md:text-base lg:text-lg xl:text-xl">
                                Lorem Ipsum
                            </h3>
                            <h4 className="poppins-regular text-dark  mt-1.5 lg:mt-1 text-xs xs:text-sm sm:text-base md:text-sm lg:text-base xl:text-lg">
                                Lorem Ipsum is simply dummy text of the printing.
                            </h4>
                        </div>
                        <div>
                            <img src={icon2} alt="" className="w-9 xxs:w-12 sm:w-16 md:w-14"/>
                            <h3 className=" poppins-bold text-dark mt-6 text-sm xs:text-base sm:text-lg md:text-base lg:text-lg xl:text-xl">
                                Lorem Ipsum
                            </h3>
                            <h4 className="poppins-regular text-dark  mt-1.5 lg:mt-1 text-xs xs:text-sm sm:text-base md:text-sm lg:text-base xl:text-lg">
                                Lorem Ipsum is simply dummy text of the printing.
                            </h4>
                        </div>
                        <div>
                            <img src={icon3} alt="" className="w-9 xxs:w-12 sm:w-16 md:w-14"/>
                            <h3 className=" poppins-bold text-dark mt-6 text-sm xs:text-base sm:text-lg md:text-base lg:text-lg xl:text-xl">
                                Lorem Ipsum
                            </h3>
                            <h4 className="poppins-regular text-dark  mt-1.5 lg:mt-1 text-xs xs:text-sm sm:text-base md:text-sm lg:text-base xl:text-lg">
                                Lorem Ipsum is simply dummy text of the printing.
                            </h4>
                        </div>
                        <div>
                            <img src={icon4} alt="" className="w-9 xxs:w-12 sm:w-16 md:w-14"/>
                            <h3 className=" poppins-bold text-dark mt-6 text-sm xs:text-base sm:text-lg md:text-base lg:text-lg xl:text-xl">
                                Lorem Ipsum
                            </h3>
                            <h4 className="poppins-regular text-dark mt-1.5 lg:mt-1 text-xs xs:text-sm sm:text-base md:text-sm lg:text-base xl:text-lg">
                                Lorem Ipsum is simply dummy text of the printing.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute w-full top-0 left-0 rotate-180">
                <img src={divider} alt="" className=" h-full w-full"/>
            </div>
            <div className="absolute w-full bottom-0 left-0">
                <img src={divider} alt="" className=" h-full w-full"/>
            </div>
        </div>
    );
}

export default Section3;
