import React from "react";
import facebook from "./images/FaceBook.svg";
import instagram from "./images/Instagram.svg";
function Footer() {
  return (
    <div className="  bg-dark h-min ">
      <div className="2xl:container 2xl:mx-auto flex flex-col md:flex-row justify-between  px-6 sm:px-8 py-9  xs:py-10 sm:px-12 lg:px-20  xxs:py-8 lg:py-12 xl:px-24">
        <div className="lg:w-1/3">
          <h1 className="text-white poppins-bold text-2xl xs:text-3xl md:text-2xl xl:text-3xl">
            Aimila.
          </h1>
          <div className="flex flex-row mt-4 xxs:mt-5 md:mt-3 lg:mt-6 space-x-3">
            <img
              src={facebook}
              alt=""
              className="w-9 xxs:w-10 xs:w-11 md:w-10"
            />
            <img
              src={instagram}
              alt=""
              className="w-9  xxs:w-10 xs:w-11 md:w-10"
            />
          </div>
        </div>

        <div className="mt-6 xxs:mt-8 sm:mt-10 md:mt-0 grid grid-cols-2 grid-rows-2 gap-12 sm:gap-22 md:gap-18 lg:gap-14 xl:gap-22 2xl:gap-28  sm:flex sm:flex-row ">
          <div className="flex flex-col space-y-4 sm:space-y-3">
            <h1 className="poppins-bold text-white text-base xs:text-lg md:text-base xl:text-lg">
              Lorem ipsum
            </h1>
            <h2 className="poppins-regular text-primarylight text-sm xs:text-base md:text-sm xl:text-base">
              Lorem ipsum
            </h2>
            <h2 className="poppins-regular text-primarylight text-sm xs:text-base md:text-sm xl:text-base">
              Lorem ipsum
            </h2>
            <h2 className="poppins-regular text-primarylight text-sm xs:text-base md:text-sm xl:text-base">
              Lorem ipsum
            </h2>
          </div>
          <div className="flex flex-col space-y-4 sm:space-y-3">
            <h1 className="poppins-bold text-white text-base xs:text-lg md:text-base xl:text-lg">
              Lorem ipsum
            </h1>
            <h2 className="poppins-regular text-primarylight text-sm xs:text-base md:text-sm xl:text-base">
              Lorem ipsum
            </h2>
            <h2 className="poppins-regular text-primarylight text-sm xs:text-base md:text-sm xl:text-base">
              Lorem ipsum
            </h2>
            <h2 className="poppins-regular text-primarylight text-sm xs:text-base md:text-sm xl:text-base">
              Lorem ipsum
            </h2>
          </div>
          <div className="flex flex-col space-y-4 sm:space-y-3">
            <h1 className="poppins-bold text-white text-base xs:text-lg md:text-base xl:text-lg">
              Lorem ipsum
            </h1>
            <h2 className="poppins-regular text-primarylight text-sm xs:text-base md:text-sm xl:text-base">
              Lorem ipsum
            </h2>
            <h2 className="poppins-regular text-primarylight text-sm xs:text-base md:text-sm xl:text-base">
              Lorem ipsum
            </h2>
            <h2 className="poppins-regular text-primarylight text-sm xs:text-base md:text-sm xl:text-base">
              Lorem ipsum
            </h2>
          </div>
        </div>
      </div>

      <div className="  w-screen border-footer bg-dark ">
        <h1 className="poppins-regular text-primarylight text-center py-6 text-xs xs:text-sm md:text-xs xl:text-sm">
          Copyright 2022 Dacodus
        </h1>
      </div>
    </div>
  );
}

export default Footer;
