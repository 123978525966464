import DashboardNavbar from "./DashboardNavbar";
import Settings from "./Settings";
import History from "./History.js";

export default function Dashboard_PaymentHistory() {
    return (
        <div className="">
            <DashboardNavbar/>
            <div className="2xl:container 2xl:mx-auto py-2 sm:py-4 xl:py-8">
                <div
                    className="space-y-6  lg:space-y-0 lg:flex flex-row justify-between   py-3 xs:py-4 px-3  xxs:px-4 xs:px-6  sm:px-12 lg:px-20  xl:px-24 2xl:px-0 lg:py-3">
                    <Settings/>
                    <div className="lg:w-2/3 2xl:w-3/4">
                        <History/>
                    </div>
                </div>
            </div>
        </div>
    )
}