import React from "react";
import Navbar from "./Navbar";
import ResetPasswordForm from "./forms/ResetPasswordForm";
import Footer from "./Footer";

function ResetPassword() {
    return (
        <div>
            <Navbar/>
            <ResetPasswordForm/>
            <Footer />
        </div>
    )
}

export default ResetPassword;