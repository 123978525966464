import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import SuperAdminNavbar from "./SuperAdminNavbar";
import JoditEditor from "jodit-react";
import axios from "axios";
import {getToken} from "../helpers/auth";
import Button from '@material-ui/core/Button';
import {ImageViewer} from "react-image-viewer-dv";
import {Dialog, Transition} from "@headlessui/react";
import { Listbox } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'



function SA_addNews() {

    const editor = useRef(null)
    const [content, setContent] = useState('')
    const [currentItem, setCurrentItem] =useState();
    const [activeItem, setActiveItem] = useState();
    const [openModal, setOpenModal] = useState(false);
    const cancelButtonRef = useRef(null);
    const [placeholder, setPlaceholder] = useState()
    const config = useCallback({
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        placeholder: placeholder || 'Start typings...'
    }, [placeholder])

    const [formData, setFormData] = useState({
        title:"",
        description:"",
        total_amount:"",
        percent:"",
        name:"",
        file:"",
        admins: "",
    });

    const {title, total_amount, percent, name, file, admins} = formData

    const handleSubmit = (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", content);
        formData.append("total_amount", total_amount);
        formData.append("percent", percent);
        formData.append("name", name);
        formData.append("file", file);
        formData.append("admins", admins);
        console.log("formData", formData)
        axios
            .post(
                `${process.env.REACT_APP_API_URL}super_admin/create/news_feed`,
                formData,
                {
                    headers: {
                        Authorization: "Bearer " + getToken(),
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((result) => {
                console.log(result);
            })
            .catch((err) => {});
    };


    const handleChange = (text) => (e) => {
        setFormData({ ...formData, [text]: e.target.value });
    };

    const[image, setImage] = useState()
    const handleFileSelect = (e) => {
        setFormData({...formData, ["file"]: e.target.files[0]})
        setImage(e.target.files[0]);
    }
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState()
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}super_admin/admins`,
                {
                    headers: {
                        Authorization: "Bearer " + getToken(),
                    },
                }
            )
            .then((res) => {
                setData(res.data);
                console.log(res);
            })
            .catch((err) => {});
    }, []);


    return(
        <div className={`h-screen bg-palegrey`}>
            <div className=" md:flex md:flex-row">
                <SuperAdminNavbar/>


                <form onSubmit={handleSubmit} className={`pb-20 xl:mx-auto w-full  px-6 sm:px-8 lg:px-12 xl:px-40 2xl:px-50 3xl:px-80`}>
                    <h1 className={`poppins-bold text-2xl xs:text-3xl xl:text-4xl mt-8 sm:mt-10  lg:mt-14 xl:mt-16 2xl:mt-18`}>Adaugă Caz</h1>
                    <div className={`flex flex-col sm:grid sm:grid-cols-2 sm:gap-8 lg:gap-12 xl:gap-20 lg:w-4/5`}>
                    <div className={`w-full`}>
                    <h2 className={`poppins-semi text-lg xs:text-xl xl:text-2xl mt-6 xl:mt-8`}>Imagine</h2>
                        <div className={`flex flex-row items-center space-x-4 mt-3 xl:mt-6`}>
                        <ImageViewer>
                            <img className={`w-50 h-16 lg:w-40 xl:h-18 rounded-3xl`} src={image} alt=""/>
                        </ImageViewer>
                        <input className={`hidden`} type="file" multiple onChange={handleFileSelect}  id="file"/>
                        <div  className={`poppins-regular text-xs xs:text-sm xl:text-base text-gray text-center px-10 py-3 xs:px-16 xs:py-5 sm:px-10 md:px-6 2xl:px-10 rounded-2xl xs:rounded-3xl border border-2 border-dashed mt-3 xl:mt-6`}><label htmlFor="file">Faceți clic pentru a răsfoi sau trage și plasați fișierele</label></div>
                        </div>
                        </div>
                    <div className={`w-full`}>
                        <h2 className={`poppins-semi text-lg xs:text-xl xl:text-2xl mt-6 xl:mt-8`}>Titlu</h2>
                        <input type="text" id="titlu"  placeholder="ex: Casa in constructie"  value={title} onChange={handleChange("title")}
                               className="w-full mt-2 sm:mt-6 xl:mt-8 border border-lightgray rounded-xl text-xs xs:text-sm xl:text-base xxs:rounded-2xl py-2 px-3 xxs:py-3 xxs:px-4"/>
                    </div>
                        <div className={`w-full`}>
                            <h2 className={`poppins-semi text-lg xs:text-xl xl:text-2xl mt-6 xl:mt-8`}>Nume/ Actiune</h2>
                            <input type="text" id="nume"  placeholder="Andrei/ Casa de locuit"  value={name} onChange={handleChange("name")}
                                   className="w-full mt-2 sm:mt-6 xl:mt-8 border border-lightgray rounded-xl text-xs xs:text-sm xl:text-base xxs:rounded-2xl py-2 px-3 xxs:py-3 xxs:px-4"/>
                        </div>
                        <div className={`w-full`}>
                            <h2 className={`poppins-semi text-lg xs:text-xl xl:text-2xl mt-6 xl:mt-8`}>Suma</h2>
                            <input type="text" id="suma"  placeholder="445"  value={total_amount} onChange={handleChange("total_amount")}
                                   className="w-full sm:w-3/5 mt-2 sm:mt-6 xl:mt-8 border border-lightgray rounded-xl text-xs xs:text-sm xl:text-base xxs:rounded-2xl py-2 px-3 xxs:py-3 xxs:px-4"/>
                        </div>
                    </div>
                    <div className={`mt-8 xl:mt-10`}>
                    <JoditEditor
                        ref={editor}
                        value={content}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => {
                        }}

                    /></div>
                    <div className={`flex flex-row place-content-end`}>
                    <button
                        type="submit"
                        className="flex items-center poppins-regular text-white bg-darkgreen rounded-xl xs:rounded-2xl xl:rounded-3xl py-2.5 px-4 xs:px-6 xs:py-3 xl:py-4 xl:px-8 mt-6 md:mt-10 xl:mt-12"
                    >
                        <svg className={`w-5 h-5`} width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.3334 8.99984H1.66675M9.00008 1.6665V16.3332V1.6665Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <h1 className={`ml-2 xl:ml-4 text-xs xs:text-sm  xl:text-lg`}>Adaugă caz</h1>
                    </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SA_addNews;