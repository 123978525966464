import React from 'react';
import { Navigate} from 'react-router-dom';
import SA_newsFeed from "../superadmin/SA_newsFeed";
import SA_dashboard from "../superadmin/SA_dashboard";
import SA_addNews from "../superadmin/SA_addNews";
import SA_editNews from "../superadmin/SA_editNews";

export const SAdashboard = () => {

    return  isAuth() && isAuth().type === 'superAdmin'  ? <SA_dashboard /> : <Navigate to="/signin" />;
}

export const SAnewsFeed = () => {

    return  isAuth() && isAuth().type === 'superAdmin'  ? <SA_newsFeed /> : <Navigate to="/signin" />;
}

export const SAaddNews = () => {

    return  isAuth() && isAuth().type === 'superAdmin'  ? <SA_addNews /> : <Navigate to="/signin" />;
}

export const SAeditNews = () => {

    return  isAuth() && isAuth().type === 'superAdmin'  ? <SA_editNews /> : <Navigate to="/signin" />;
}


export const isAuth = () => {
    const user = localStorage.getItem('user')

    if (!user) {
        return;
    }

    return JSON.parse(user);
}

export const getToken = () => {
    const user = localStorage.getItem('user')

    if (!user) {
        return;
    }

    return JSON.parse(user).token;
}

export const creditCard = () => {
    const card = localStorage.getItem('card')

    // if user not have card
    if (!JSON?.parse(card)) {
        return null;
    }
    return JSON.parse(card);
}
export const userProfile = () => {
    const user = localStorage.getItem('user')
    return JSON.parse(user);
}



