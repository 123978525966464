import React from "react";
import BG1 from "../images/BG1.svg";
import BG2 from "../images/BG2.svg";
import BG3 from "../images/BG3.svg";
import connector from "../images/Connector Vector.svg";
import connectormobile from "../images/ConnectorMobile.svg";
import checkmark from "../images/Checkmark.svg";
import fakebtn from "../images/Fake Button.svg";
import avatar from "../images/Avatar.svg";
import ring from "../images/Avatar Ring.svg";

function Section2() {
  return (
    <div className=" 2xl:container 2xl:mx-auto bg-white h-min mt-10 lg:pt-20 xl:pt-32 lg:px-20 xl:px-24  relative">
      <div className="">
        <h1 className="text-center poppins-bold text-dark text-lg xs:text-2xl sm:text-3xl md:text-2xl lg:text-3xl xl:text-4xl">
          Cum functioneaza Aimila ?
        </h1>
      </div>
      <div className="flex flex-col md:flex-row justify-between relative m-auto mt-10  pb-8 sm:mt-16 md:mt-12 lg:mt-14 xl:mt-16 w-64 xxs:w-68 xs:w-72 sm:w-96 md:w-160 lg:w-216 xl:w-280">
        <img
          src={connector}
          alt=""
          className="absolute hidden md:block w-100 lg:w-140 xl:w-180 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-32"
        />
        <img
          src={connectormobile}
          alt=""
          className="absolute md:hidden  h-160 xs:h-168 sm:h-200 left-1/2 transform -translate-x-1/2 translate-y-53"
        />

        <div className="flex flex-col items-center mb-6 sm:mb-10 md:w-50 lg:w-64 xl:w-80">
          <div className="relative">
            <img src={BG1} alt="" className="sm:w-72 lg:w-64 xl:w-80" />
            <div className="absolute z-10 bg-white flex flex-col rounded-2xl lg:rounded-3xl w-40 xxs:w-44 md:w-32 lg:w-42 xl:w-52 py-4 md:py-3 lg:py-4 px-4 md:px-3 lg:px-5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="flex flex-col poppins-bold text-darkgreen text-sm md:text-xs lg:text-sm xl:text-base">
                <h3>Lorem ipsum</h3>
                <svg
                  width="130"
                  height="11"
                  viewBox="0 0 273 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-1 xxs:mt-2 md:mt-1 xl:mt-1.5 pr-2 lg:pr-0 xl:w-36"
                >
                  <rect
                    width="190"
                    height="13"
                    rx="5.5"
                    fill="#20484F"
                    fill-opacity="0.1"
                  />
                </svg>
                <svg
                  width="130"
                  height="11"
                  viewBox="0 0 273 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-0.5 xxs:mt-1 md:mt-0 lg:mt-1 pr-2 lg:pr-0 xl:w-40"
                >
                  <rect
                    width="230"
                    height="13"
                    rx="5.5"
                    fill="#20484F"
                    fill-opacity="0.1"
                  />
                </svg>
                <div className="flex flex-row items-center mt-4 md:mt-2 xxs:mt-5 lg:mt-6 xl:mt-8">
                  <img src={checkmark} alt="" className="w-3 xl:w-4" />
                  <svg
                    width="130"
                    height="11"
                    viewBox="0 0 273 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" pr-2 lg:pr-0 ml-2"
                  >
                    <rect
                      width="100"
                      height="13"
                      rx="5.5"
                      fill="#20484F"
                      fill-opacity="0.1"
                    />
                  </svg>
                </div>
                <div className="flex flex-row items-center mt-1 lg:mt-1 ">
                  <img src={checkmark} alt="" className="w-3 xl:w-4 " />
                  <svg
                    width="130"
                    height="11"
                    viewBox="0 0 273 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="pr-2 lg:pr-0 ml-2 "
                  >
                    <rect
                      width="100"
                      height="13"
                      rx="5.5"
                      fill="#20484F"
                      fill-opacity="0.1"
                    />
                  </svg>
                </div>
                <div className="flex flex-row items-center mt-1 lg:mt-1.5 ">
                  <img src={checkmark} alt="" className="w-3 xl:w-4 " />
                  <svg
                    width="130"
                    height="11"
                    viewBox="0 0 273 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" pr-2 lg:pr-0 ml-2"
                  >
                    <rect
                      width="175"
                      height="13"
                      rx="5.5"
                      fill="#20484F"
                      fill-opacity="0.1"
                    />
                  </svg>
                </div>
                <img src={fakebtn} alt="" className="mt-5 xl:mt-7 " />
              </div>
            </div>
          </div>
          <h2 className="poppins-bold text-dark text-center mt-8 sm:mt-10 xl:mt-11 lg:mt-8 text-lg xs:text-xl sm:text-2xl md:text-lg lg:text-xl xl:text-2xl">
            Lorem Ipsum
          </h2>
          <h3 className="poppins-regular text-dark text-center mt-1.5  sm:mt-2 xl:mt-3 text-sm xs:text-base sm:text-lg md:text-sm lg:text-base xl:text-lg">
            Lorem Ipsum is simply dummy text of the printing.
          </h3>
        </div>

        <div className="flex flex-col items-center mb-6 sm:mb-10 md:w-50 lg:w-64 xl:w-80">
          <div className="relative">
            <img src={BG2} alt="" className="sm:w-72 lg:w-64 xl:w-80" />
            <div className="absolute z-10  bg-white flex flex-row rounded-xl lg:rounded-3xl w-60 xxs:w-64 md:w-50 lg:w-68 xl:w-84  py-2 md:py-3 lg:py-4  px-2 lg:px-6  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <img
                src={checkmark}
                alt=""
                className="w-10 md:w-7 lg:w-10 xl:w-12  pb-4"
              />
              <div className="flex flex-col poppins-bold text-darkgreen ml-2  text-base md:text-sm lg:text-base xl:text-lg">
                <h3>Lorem ipsum</h3>
                <svg
                  viewBox="0 0 260 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-1 lg:mt-2 xl:mt-2.5  ml-1  pr-2 lg:pr-0 md:w-36 lg:w-44 xl:w-56 "
                >
                  <rect
                    width="260"
                    height="10"
                    rx="5.5"
                    fill="#20484F"
                    fill-opacity="0.1"
                  />
                </svg>

                <svg
                  viewBox="0 0 260 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-1 md:mt-1.5 lg:mt-2 xl:mt-2.5  ml-1  pr-2 lg:pr-0 md:w-32 lg:w-36 xl:w-48"
                >
                  <rect
                    width="260"
                    height="12"
                    rx="5.5"
                    fill="#20484F"
                    fill-opacity="0.1"
                  />
                </svg>
              </div>
            </div>
          </div>
          <h2 className="poppins-bold text-dark text-center mt-8 sm:mt-10 md:mt-11 lg:mt-9 xl:mt-12 text-lg xs:text-xl sm:text-2xl md:text-lg lg:text-xl xl:text-2xl">
            Lorem Ipsum
          </h2>
          <h3 className="poppins-regular text-dark text-center mt-1.5 sm:mt-2 xl:mt-3 text-sm  xs:text-base sm:text-lg md:text-sm lg:text-base xl:text-lg">
            Lorem Ipsum is simply dummy text of the printing.
          </h3>
        </div>

        <div className="flex flex-col items-center mb-6 md:w-50 lg:w-64  xl:w-80">
          <div className="relative">
            <img src={BG3} alt="" className="sm:w-72 lg:w-64 xl:w-80" />
            <div className="absolute z-10 bg-white flex flex-col items-center rounded-2xl lg:rounded-2xl  w-40 xxs:w-44 md:w-34 lg:w-40 xl:w-50  md:h-38 lg:h-44 xl:h-56  py-3 xxs:py-4 md:py-2.5 lg:py-3 xl:py-4  px-3 lg:px-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="relative">
                <img
                  src={ring}
                  alt=""
                  className="w-14 xxs:w-16 md:w-14 xl:w-16 pb-4 md:pb-2 lg:pb-3 xl:pb-5"
                />
                <div>
                  <img
                    src={avatar}
                    alt=""
                    className="absolute top-7 xxs:top-8 md:top-7 xl:top-8  left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 xxs:w-14 md:w-12 xl:w-14 "
                  />
                </div>
              </div>
              <div className="flex flex-col items-center  text-darkgreen ">
                <h3 className="poppins-bold text-sm  text-sm md:text-xs lg:text-sm xl:text-base">
                  Anneta Wilson
                </h3>
                <h4 className="poppins-regular lg:mt-1 text-xs xl:text-sm">
                  15 ani
                </h4>
                <svg
                  viewBox="0 0 200 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-3 md:mt-2 lg:mt-3 w-32 md:w-24 lg:w-28 xl:w-36 "
                >
                  <rect
                    width="190"
                    height="13"
                    rx="5.5"
                    fill="#20484F"
                    fill-opacity="0.1"
                  />
                </svg>
                <svg
                  viewBox="0 0 200 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-2 lg:mt-2.5 xl:mt-3  w-20 md:w-16 xl:w-28 "
                >
                  <rect
                    width="190"
                    height="19"
                    rx="8"
                    fill="#20484F"
                    fill-opacity="0.1"
                  />
                </svg>
              </div>
            </div>
          </div>
          <h2 className="poppins-bold text-dark text-center mt-8 sm:mt-12 md:mt-8 lg:mt-6 xl:mt-8  text-lg xs:text-xl sm:text-2xl md:text-lg lg:text-xl xl:text-2xl">
            Lorem Ipsum
          </h2>
          <h3 className="poppins-regular text-dark text-center mt-1.5 sm:mt-2 xl:mt-3 text-sm xs:text-base sm:text-lg md:text-sm lg:text-base xl:text-lg">
            Lorem Ipsum is simply dummy text of the printing.
          </h3>
        </div>
      </div>
    </div>
  );
}

export default Section2;
