import React, { useState } from "react";
import decorationTopLeft from "../images/DecorationTopLeft.svg";
import decorationBottomRight from "../images/DecorationBottomRight.svg";
import useForm from "./useForm";
import validate from "./LoginFormValidationRules";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SignUpForm = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const { values, errors } = useForm(login, validate);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  function login() {}

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const { username, email, password } = formData;

  const signUpUser = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}auth/register`, {
        username,
        email,
        password,
      })
      .then((result) => {
        navigate("/signin?email=" + email + "&success=true");
      })
      .catch((err) => {
        navigate("/signin?email=" + email + "&success=false");
      });
  };

  const handleChangeSignUp = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value });
  };

  const [checked, setChecked] = useState(false);

  return (
    <div className="relative bg-primarylight py-14 xs:py-18 lg:py-24 ">
      <img
        src={decorationTopLeft}
        alt=""
        className="hidden sm:block absolute top-0 left-0 sm:w-32 lg:w-40 xl:w-50"
      />
      <img
        src={decorationBottomRight}
        alt=""
        className="hidden sm:block absolute bottom-0 right-0 sm:w-32 lg:w-40 xl:w-50"
      />
      <div className="2xl:container 2xl:mx-auto relative">
        <div className="flex flex-col bg-white rounded-4xl m-auto w-10/12 sm:w-8/12 md:w-3/6 lg:w-2/5 xl:w-2/5 px-4 md:px-6 lg:px-10 py-7 lg:py-8 xl:py-12">
          <h1 className="text-center poppins-bold text-darkgreen text-xl md:text-2xl lg:text-3xl">
            Înregistrează-te
          </h1>
           {errorMessage.display && !checked ? (<div className={`w-4/5 m-auto flex flex-row justify-between items-center space-x-2 py-2 px-6 rounded-xl bg-error`}>
                      <div className={`flex flex-row space-x-2 items-center`}> <svg width="19" height="17" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 7.625V9.54167V7.625ZM10.5 13.375H10.5096H10.5ZM3.86066 17.2083H17.1393C18.6152 17.2083 19.5371 15.6108 18.7992 14.3333L12.1598 2.83334C11.4219 1.55588 9.57807 1.55588 8.84016 2.83334L2.20082 14.3333C1.46291 15.6108 2.38482 17.2083 3.86066 17.2083Z" stroke="#EB2222" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                        <p className="error text-sm poppins-semi text-rederror">{errorMessage.message} </p>
                      </div>
                      <button
                          onClick={() => {
                            setErrorMessage({message: errorMessage.message, display: false})}
                          }>
                        <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.5 1.5L12.5 12.5M1.5 12.5L12.5 1.5L1.5 12.5Z" stroke="#EB2222" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </button>
                    </div>
                ) : null}

          <form
            onSubmit={signUpUser}
            className="flex flex-col poppins-regular text-dark text-xs xs:text-sm lg:text-base"
          >
            <label className="mt-4 lg:mt-8">Nume</label>
            <input
              type="text"
              id="username"
              placeholder="Introduceți numele"
              name="username"
              className=" border-button placeholder-lightgray rounded-2xl px-3 py-2.5 mt-2 focus:outline-none"
              onChange={handleChangeSignUp("username")}
              value={values.username}
              required
            />
            <label className="mt-3 lg:mt-4">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Introduceți email-ul"
              className=" border-button placeholder-lightgray rounded-2xl px-3 py-2.5 mt-2 focus:outline-none"
              name="email"
              onChange={handleChangeSignUp("email")}
              value={values.email}
              required
            />
            <label className="mt-3 lg:mt-4">Parola</label>
            <input
              type="password"
              id="password"
              placeholder="Introduceți parola"
              className=" border-button placeholder-lightgray rounded-2xl px-3 py-2.5 mt-2 focus:outline-none"
              name="password"
              onChange={handleChangeSignUp("password")}
              value={values.password}
              required
            />
            <div className="flex flex-row items-center mt-5 checkbox-green">
              <input
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
                className="bg-darkgreen h-5 w-5"
                required
                checked={checked}
                type="checkbox"
                id="agree"
              />
              <label htmlFor="agree" />
              <span className="ml-2 rounded-xl  items-center">
                Sunt de acord cu Termenii și condițiile
              </span>
            </div>
            <button
                onClick={() => {
                  if (!checked) {
                    setErrorMessage({message: 'Trebuie să fiți de acord cu Termenii și condițiile!', display: true});
                  }
                }}
                type="submit"
              className="poppins-regular text-white bg-darkgreen rounded-xl py-3 lg:px-9 mt-5"
            >
              Creează contul
            </button>
            <h2 className="text-center mt-3 lg:mt-5">
              Aveți deja un cont?{" "}
              <Link to="/signin" className="underline text-darkgreen">
                Conectare
              </Link>
            </h2>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
