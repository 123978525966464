import avatar from "../images/avatar.png";
import { getToken, userProfile } from "../helpers/auth";
import React, { useState } from "react";
import { isAuth } from "../helpers/auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Dashboard_Account() {
  const navigate = useNavigate();

  function Logout() {
    localStorage.clear();
    navigate("/");
  }

  const [name, setName] = useState(isAuth()?.name);
  const updateProfile = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_API_URL}user/update/profile`,
        {
          name: name,
        },
        {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((result) => {
        const { email, token, type } = isAuth();
        localStorage.setItem(
          "user",
          JSON.stringify({ email, name: result.data.name, token, type })
        );
        setName(result.data.name);
      })
      .catch((err) => {});
  };

  return (
    <div className="flex flex-col ">
      <h1 className="poppins-semi  text-neutralblack text-lg xs:text-xl sm:text-2xl md:text-xl lg:text-2xl">
        Cont
      </h1>

      <div className="flex flex-col border border-gray rounded-2xl xxs:rounded-3xl w-full mt-3 xs:mt-4 m-auto px-2.5 xxs:px-3 lg:px-5">
        <h1 className="nunito-bold md:font-poppins text-neutralblack text-lg xxs:text-xl xs:text-2xl lg:text-xl mt-4  xxs:mt-6  lg:mt-4">
          Informații personale
        </h1>

        <h2 className="poppins-semi text-neutralgray text-sm xxs:text-base lg:text-sm mt-6 lg:mt-4">
          Avatar
        </h2>
        <div className="flex flex-row items-center mt-2 ">
          <img
            src={avatar}
            alt=""
            className="w-20 h-20 xxs:w-24 xxs:h-24 lg:w-22 lg:h-22 rounded-xl xxs:rounded-2xl"
          />

          <button className="ml-4 xxs:ml-6 border border-darkgreen bg-white py-2 px-3 xxs:py-2.5 xxs:px-4 rounded-xl xxs:rounded-2xl text-darkgreen poppins-bold text-sm xxs:text-base lg:text-sm">
            Modifică
          </button>
          <button className="ml-2 xxs:ml-3 text-neutralgray poppins-bold text-sm xxs:text-base lg:text-sm">
            Șterge
          </button>
        </div>

        <form className="flex flex-col poppins-regular text-sm xxs:text-base lg:text-sm mt-6 xs:mt-8">
          <div className="flex flex-col md:flex-row md:space-x-2 lg:space-x-4">
            <div className="flex flex-col w-full">
              <label htmlFor="name" className="poppins-semi text-darkgray ">
                Nume
              </label>
              <input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                type="text"
                id="name"
                name="name"
                className="mt-1 xs:mt-2 border border-lightgray placeholder-neutralblack rounded-xl xxs:rounded-2xl py-2 px-3 xxs:py-3 xxs:px-4 outline-none"
              />
            </div>
            <div className="flex flex-col w-full">
              <label
                htmlFor="name"
                className="poppins-semi text-darkgray mt-3 md:mt-0"
              >
                Email
              </label>
              <div
                type="email"
                id="email"
                name="email"
                placeholder="jane.robertson@example.com"
                className="mt-1 xs:mt-2 border border-lightgray placeholder-neutralblack rounded-xl xxs:rounded-2xl py-2 px-3 xxs:py-3 xxs:px-4"
              >
                {userProfile()?.email}
              </div>
            </div>
          </div>
          <h1 className="nunito-bold text-neutralblack text-lg xxs:text-xl xs:text-2xl lg:text-lg mt-6 md:font-poppins">
            Notificări
          </h1>
          {/*<div className="flex flex-row items-center mt-3">*/}
          {/*    <input type="checkbox" id="notifications" name="notifications" className="appearance-none w-6 h-6 xxs:w-7 xxs:h-7  rounded-lg border border-darkgreen checked:bg-darkgreen checked:bg-email-checked bg-no-repeat bg-center bg-3"/>*/}
          {/*    <label htmlFor="notifications" className="nunito md:font-poppins text-neutralblack text-base xxs:text-lg lg:text-sm ml-2">Email</label>*/}
          {/*</div>*/}
          <div className="flex flex-row items-center mt-5 checkbox-green">
            <input
              className="bg-darkgreen h-5 w-5"
              type="checkbox"
              id="rememberMe"
            />
            <label htmlFor="rememberMe" />
            <span className="ml-2 rounded-xl  items-center">Email</span>
          </div>
          <button className="md:hidden mt-6 border border-error rounded-xl py-1.5 xxs:py-2.5 text-error nunito-bold text-base xxs:text-lg ">
            Delogare
          </button>
        </form>
        <div className="w-full flex place-content-end md:justify-between border-t mt-4 xxs:mt-10 xs:mt-12 xl:mt-16 py-3 px-3">
          <button
            onClick={Logout}
            className="hidden md:block py-2.5 lg:py-2 px-5 border border-error rounded-2xl nunito-bold text-error text-lg lg:text-base"
          >
            Delogare
          </button>
          <button
            onClick={updateProfile}
            className="shadow-md bg-darkgreen py-2.5 lg:py-2  px-5  rounded-2xl nunito-bold text-white text-base xs:text-lg lg:text-base"
          >
            Salvează
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard_Account;
