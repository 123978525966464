import React from "react";
import decoration from "../images/Decoration.svg";
function Section4() {
  return (

    <div className=" bg-white h-min relative p-6 xxs:p-8 md:p-12 sm:pb-14 lg:pb-16 xl:pb-24">
      <img
        src={decoration}
        alt=""
        className="hidden md:block absolute right-0 bottom-0 w-40 lg:w-56 xl:w-60 2xl:w-80"
      />
      <div className="2xl:container 2xl:mx-auto flex flex-col items-center text-center ">
        <h2 className="poppins-bold text-dark text-xs xxs:text-sm sm:text-xl md:text-lg lg:text-xl xl:text-2xl">
          Lorem Ipsum is simply dummy text{" "}
        </h2>
        <h1 className="poppins-bold text-dark mt-3 sm:mt-6 text-lg xxs:text-xl sm:text-4xl md:text-3xl lg:text-5xl md:w-132 lg:w-216 xl:w-230">
          Lorem Ipsum is simply dummy text of the printing.
        </h1>
        <button className="poppins-regular text-white bg-darkgreen rounded-2xl xxs:rounded-3xl  py-3 xxs:py-4 sm:py-5 md:py-4  px-4 xxs:px-5 xs:px-6 sm:px-9  mt-7 xxs:mt-9 lg:mt-10 mb-4 text-xs xxs:text-sm sm:text-lg md:text-base lg:text-lg xl:text-xl">
          Lorem ipsum
        </button>
      </div>
    </div>
  );
}

export default Section4;
