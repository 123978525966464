import React from "react";
import { render } from "react-dom";
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import "./components/css/index.css";
import HomePage from "./components/HomePage";
import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
import SeeMore from "./components/Dashboard/SeeMore";
import NewFeedDetails from "./components/NewFeedDetails";
import Account from "./components/Account";
import Card from "./components/Card";
import Dashboard_PaymentHistory from "./components/Dashboard/Dashboard_PaymentHistory";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import ActivateAccount from "./components/ActivateAccount";
import {SAdashboard, SAnewsFeed, SAaddNews, SAeditNews} from "./components/helpers/auth.js";
import DashboardNews from "./components/Dashboard/DashboardNews";


const rootElement = document.getElementById("root");



render(
  <BrowserRouter>
      <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/signup" element={<SignUp/>}/>
          <Route path="/signin" element={<SignIn/>}/>
          <Route path="/seemore" element={<SeeMore/>}/>
          <Route path="/forgot/password" element={<ForgotPassword/>}/>
          <Route path="/reset/password/:token" element={<ResetPassword/>}/>
          <Route path="/activate/account/:token" element={<ActivateAccount/>}/>
          <Route path="/dashboard/newsfeed" element={<DashboardNews/>}/>
          <Route path="/dashboard/newsfeed/details/:id" element={<NewFeedDetails/>}/>
          <Route path="/dashboard/account" element={<Account/>}/>
          <Route path="/dashboard/card" element={<Card/>}/>
          <Route path="/dashboard/paymenthistory" element={<Dashboard_PaymentHistory />} />




          <Route  path='/super-admin/dashboard' element={<SAdashboard/>}/>
          <Route  path='/super-admin/newsfeed' element={<SAnewsFeed/>}/>
          <Route  path='/super-admin/addnewsfeed' element={<SAaddNews/>}/>
          <Route  path='/super-admin/editnewsfeed/:id' element={<SAeditNews/>}/>


          <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
  </BrowserRouter>,
  rootElement
);
