import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import PropagateLoader from "./PropagateLoader.tsx";

function ActivateAccount() {
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}auth/activate/account`, {
        token,
      })
      .then(() => {
        navigate("/signin?activate=&success=true");
      })
      .catch((err) => {
        navigate("/signin?activate=&success=false");
      });
  });
  return <PropagateLoader/>;
}
export default ActivateAccount;
