import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import SuperAdminNavbar from "./SuperAdminNavbar";
import JoditEditor from "jodit-react";
import axios from "axios";
import {getToken, isAuth} from "../helpers/auth";
import {useParams} from "react-router-dom";
import {ImageViewer} from "react-image-viewer-dv";
import {data} from "autoprefixer";
import {Dialog, Transition} from "@headlessui/react";
import { Listbox } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import PropagateLoader from "../PropagateLoader.tsx";

function SA_editNews() {
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(0);
    const [blogDetails, setBlogDetails] = useState();
    const cancelButtonRef = useRef(null);
    const [activeItem, setActiveItem] = useState({type: "child", key: 0});
    const editor = useRef(null);
    const [content, setContent] = useState('')
    const [blogContent, setBlogContent] = useState('')
    const [placeholder, setPlaceholder] = useState();
    const config = useCallback(
        {
            readonly: false, // all options from https://xdsoft.net/jodit/doc/,
            placeholder: placeholder || "Start typings...",
        },
        [placeholder]
    );


    const {id} = useParams();

    const [formData, setFormData] = useState({
        total_amount: "",
        description: "",
        blogs: "",
        title: "",
        percent: "",
        name: "",
        image: "",
        admins: "",
    });


    const {total_amount, blogs, title, percent, name, image, admins} = formData;

    useEffect(() => {

        axios
            .get(
                `${process.env.REACT_APP_API_URL}super_admin/news_feed/${id}`,
                {
                    headers: {
                        Authorization: "Bearer " + getToken(),
                    },
                }
            )
            .then((res) => {
                const {total_amount, title, percent, name, description, image, blogs, admins} = res.data;
                setFormData({...formData, total_amount, title, percent, name, description, image, blogs, admins})
                setContent(description);
                console.log(res);
                console.log("admins", admins);
                setIsLoading(false);
            })
            .catch((err) => {
            });
    }, [id]);

    const handleImage = ({image}) => {
        const data = new FormData();
        data.append("file", image);

        axios
            .put(
                `${process.env.REACT_APP_API_URL}super_admin/update/news_feed/image/${id}`,
                data,
                {
                    headers: {
                        Authorization: "Bearer " + getToken(),
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((result) => {
                setFormData({...formData, ["image"]: result.data.image});
                console.log(result.data)
            })
            .catch((err) => {
            });

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .put(
                `${process.env.REACT_APP_API_URL}super_admin/update/news_feed/${id}`,
                {
                    title: title,
                    description: content,
                    total_amount: total_amount,
                    name: name,
                },
                {
                    headers: {
                        Authorization: "Bearer " + getToken(),
                    },
                }
            )
            .then((result) => {

            })
            .catch((err) => {
            });
    };


    const handleChange = text => e => {
        setFormData({...formData, [text]: e.target.value});
        console.log(e.target.value, text);
    };

    const addBlog = (e) => {
        e.preventDefault();
        axios
            .post(
                `${process.env.REACT_APP_API_URL}super_admin/create/blog/${id}`,
                {
                    description: blogContent,
                },
                {
                    headers: {
                        Authorization: "Bearer " + getToken(),
                    },
                }
            )
            .then((result) => {
                setFormData({...formData, ["blogs"]: [...formData.blogs, result.data]});
                setBlogContent("");
                setOpenModal(false);
                console.log("addBlog", result);

            })
            .catch((err) => {
            });
    };

    const editBlog = (e) => {
        e.preventDefault();
        axios
            .put(
                `${process.env.REACT_APP_API_URL}super_admin/update/blog/${blogDetails._id}`,
                {
                    description: blogContent,
                },
                {
                    headers: {
                        Authorization: "Bearer " + getToken(),
                    },
                }
            )
            .then((result) => {
                const index_obj = formData.blogs.findIndex(
                    (blog) => blog._id === result.data._id
                );
                const newBlogs = [...blogs];
                newBlogs[index_obj] = result.data;
                setFormData({...formData, ["blogs"]: newBlogs});
                setOpenModal(false);

            })
            .catch((err) => {
            });
    };

    const [data, setData] = useState([]);
    const [selected, setSelected] = useState()

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}super_admin/admins`,
                {
                    headers: {
                        Authorization: "Bearer " + getToken(),
                    },
                }
            )
            .then((res) => {
                setData(res.data);
                console.log("getadmin", res);
            })
            .catch((err) => {
            });
    }, [id]);

    const addAdmin = (e) => {
        e.preventDefault();
        console.log("selected", selected);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}super_admin/add/admin/${id}`,
                {
                    adminId: selected._id,
                },
                {
                    headers: {
                        Authorization: "Bearer " + getToken(),
                    },
                }
            )
            .then((result) => {
                setFormData({...formData, ["admins"]: [...formData.admins, result.data]});
                setOpenModal(false);
                console.log("addAdmin", result);


            })
            .catch((err) => {
            });
    };

    const removeAdmin = ({adminId}) => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}super_admin/remove/admin/${id}`,
                {
                    adminId: adminId,
                },
                {
                    headers: {
                        Authorization: "Bearer " + getToken(),
                    },
                }
            )
            .then((result) => {
                setOpenModal(false);
                console.log("removeAdmin", result);
            })
            .catch((err) => {
            });
    };


    if (isLoading) {
        return (<PropagateLoader/>)
    } else {
        return (

            <div className={`h-screen bg-palegrey`}>
                <div className=" md:flex md:flex-row">
                    <SuperAdminNavbar/>
                    <Transition.Root show={openModal} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed z-30 inset-0 overflow-y-auto"
                            initialFocus={cancelButtonRef}
                            onClose={setOpenModal}
                        >
                            <div
                                className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-70"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay
                                        className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-gray-300 bg-opacity-60 transition-opacity"/>
                                </Transition.Child>
                                <span
                                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                    aria-hidden="true"
                                >
              &#8203;
            </span>
                                {activeItem.type === "addblog" ? (
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        >
                                            <div
                                                className="inline-block align-bottom bg-white rounded-3xl md:rounded-4xl text-left overflow-hidden shadow-xl transform transition-all w-11/12 sm:w-9/12 sm:my-8 sm:align-middle md:w-2xl xl:w-3/5">
                                                <div className="relative bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                    <div className="w-full">
                                                        <button
                                                            onClick={() => {
                                                                setOpenModal(false);
                                                            }}
                                                            className="absolute top-3 right-3"
                                                        >
                                                            <svg
                                                                className="w-7 h-7"
                                                                viewBox="0 0 38 38"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M15.8333 22.1667L19 19L15.8333 22.1667ZM19 19L22.1667 15.8333L19 19ZM19 19L15.8333 15.8333L19 19ZM19 19L22.1667 22.1667L19 19ZM33.25 19C33.25 20.8713 32.8814 22.7243 32.1653 24.4532C31.4492 26.1821 30.3995 27.753 29.0763 29.0763C27.753 30.3995 26.1821 31.4492 24.4532 32.1653C22.7243 32.8814 20.8713 33.25 19 33.25C17.1287 33.25 15.2757 32.8814 13.5468 32.1653C11.8179 31.4492 10.247 30.3995 8.92373 29.0763C7.60049 27.753 6.55085 26.1821 5.83472 24.4532C5.11859 22.7243 4.75 20.8713 4.75 19C4.75 15.2207 6.25133 11.5961 8.92373 8.92373C11.5961 6.25134 15.2207 4.75 19 4.75C22.7793 4.75 26.4039 6.25134 29.0763 8.92373C31.7487 11.5961 33.25 15.2207 33.25 19Z"
                                                                    stroke="#20484F"
                                                                    strokeWidth="2.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div className={`p-4`}>
                                                        <h1 className={`poppins-bold text-2xl xs:text-3xl xl:text-4xl mb-8`}>Adaugă
                                                            Blog</h1>
                                                        <JoditEditor
                                                            ref={editor}
                                                            value={blogContent}
                                                            config={config}
                                                            tabIndex={1} // tabIndex of textarea
                                                            onBlur={newBlogContent => setBlogContent(newBlogContent)} // preferred to use only this option to update the content for performance reasons
                                                            onChange={newBlogContent => {
                                                            }}

                                                        />
                                                        <div className={`flex flex-row place-content-end`}>
                                                            <button
                                                                onClick={addBlog}
                                                                className="flex items-center poppins-regular text-white bg-darkgreen rounded-xl xs:rounded-2xl xl:rounded-3xl py-2.5 px-7 xs:px-9 xs:py-3 xl:py-4 xl:px-8 mt-6 md:mt-10 xl:mt-12"
                                                            >

                                                                <h1 className={` text-xs xs:text-sm  lg:text-base xl:text-lg`}>Adaugă</h1>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition.Child>)
                                    : activeItem.type === "blog" ?
                                        (<Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                                <div
                                                    className="inline-block align-bottom bg-white rounded-3xl md:rounded-4xl text-left overflow-hidden shadow-xl transform transition-all w-11/12 sm:w-9/12 sm:my-8 sm:align-middle md:w-2xl xl:w-3/5">
                                                    <div className="relative bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                        <div className="w-full">
                                                            <button
                                                                onClick={() => {
                                                                    setOpenModal(false);
                                                                }}
                                                                className="absolute top-3 right-3"
                                                            >
                                                                <svg
                                                                    className="w-7 h-7"
                                                                    viewBox="0 0 38 38"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M15.8333 22.1667L19 19L15.8333 22.1667ZM19 19L22.1667 15.8333L19 19ZM19 19L15.8333 15.8333L19 19ZM19 19L22.1667 22.1667L19 19ZM33.25 19C33.25 20.8713 32.8814 22.7243 32.1653 24.4532C31.4492 26.1821 30.3995 27.753 29.0763 29.0763C27.753 30.3995 26.1821 31.4492 24.4532 32.1653C22.7243 32.8814 20.8713 33.25 19 33.25C17.1287 33.25 15.2757 32.8814 13.5468 32.1653C11.8179 31.4492 10.247 30.3995 8.92373 29.0763C7.60049 27.753 6.55085 26.1821 5.83472 24.4532C5.11859 22.7243 4.75 20.8713 4.75 19C4.75 15.2207 6.25133 11.5961 8.92373 8.92373C11.5961 6.25134 15.2207 4.75 19 4.75C22.7793 4.75 26.4039 6.25134 29.0763 8.92373C31.7487 11.5961 33.25 15.2207 33.25 19Z"
                                                                        stroke="#20484F"
                                                                        strokeWidth="2.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div className={`p-4`}>
                                                            <h1 className={`poppins-bold text-2xl xs:text-3xl xl:text-4xl mb-8`}>Editează
                                                                Blog</h1>
                                                            <JoditEditor
                                                                ref={editor}
                                                                value={blogContent}
                                                                config={config}
                                                                tabIndex={1} // tabIndex of textarea
                                                                onBlur={newBlogContent => setBlogContent(newBlogContent)} // preferred to use only this option to update the content for performance reasons
                                                                onChange={newBlogContent => {
                                                                }}

                                                            />
                                                            <div className={`flex flex-row place-content-end`}>
                                                                <button
                                                                    onClick={editBlog}
                                                                    className="flex items-center poppins-regular text-white bg-darkgreen rounded-xl xs:rounded-2xl xl:rounded-3xl py-2.5 px-7 xs:px-9 xs:py-3 xl:py-4 xl:px-8 mt-6 md:mt-10 xl:mt-12"
                                                                >

                                                                    <h1 className={` text-xs xs:text-sm  lg:text-base xl:text-lg`}>Salvează</h1>

                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Transition.Child>
                                        ) : activeItem.type === "addAdmin" ? (
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                                <div
                                                    className="inline-block align-bottom bg-white rounded-3xl md:rounded-4xl text-left overflow-hidden shadow-xl transform transition-all w-11/12 sm:w-9/12 sm:my-8 sm:align-middle md:w-2xl xl:w-3/5">
                                                    <div className="relative bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                        <div className="w-full">
                                                            <button
                                                                onClick={() => {
                                                                    setOpenModal(false);
                                                                }}
                                                                className="absolute top-3 right-3"
                                                            >
                                                                <svg
                                                                    className="w-7 h-7"
                                                                    viewBox="0 0 38 38"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M15.8333 22.1667L19 19L15.8333 22.1667ZM19 19L22.1667 15.8333L19 19ZM19 19L15.8333 15.8333L19 19ZM19 19L22.1667 22.1667L19 19ZM33.25 19C33.25 20.8713 32.8814 22.7243 32.1653 24.4532C31.4492 26.1821 30.3995 27.753 29.0763 29.0763C27.753 30.3995 26.1821 31.4492 24.4532 32.1653C22.7243 32.8814 20.8713 33.25 19 33.25C17.1287 33.25 15.2757 32.8814 13.5468 32.1653C11.8179 31.4492 10.247 30.3995 8.92373 29.0763C7.60049 27.753 6.55085 26.1821 5.83472 24.4532C5.11859 22.7243 4.75 20.8713 4.75 19C4.75 15.2207 6.25133 11.5961 8.92373 8.92373C11.5961 6.25134 15.2207 4.75 19 4.75C22.7793 4.75 26.4039 6.25134 29.0763 8.92373C31.7487 11.5961 33.25 15.2207 33.25 19Z"
                                                                        stroke="#20484F"
                                                                        strokeWidth="2.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div className={`p-4`}>
                                                            <h1 className={`poppins-bold text-2xl xs:text-3xl xl:text-4xl mb-8`}>Adaugă
                                                                Admin</h1>

                                                            <form onSubmit={addAdmin} className="m-auto mt-8 w-3/5">
                                                                <Listbox value={selected} onChange={setSelected}>
                                                                    <div className="relative mt-1">
                                                                        <Listbox.Button
                                                                            className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-400 sm:text-sm md:text-md lg:text-lg xl:text-xl">
                                                                            {selected ? <span
                                                                                    className="block truncate">{selected.name}</span> :
                                                                                <span className="block truncate">Selectează Admin</span>}
                                                                            <span
                                                                                className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
              />
            </span>
                                                                        </Listbox.Button>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            leave="transition ease-in duration-100"
                                                                            leaveFrom="opacity-100"
                                                                            leaveTo="opacity-0"
                                                                        >
                                                                            <Listbox.Options
                                                                                className=" mt-1 max-h-50 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm md:text-md lg:text-lg xl:text-xl">
                                                                                {Object.keys(data).map((u, i) => (
                                                                                    <Listbox.Option
                                                                                        key={i}
                                                                                        className={({active}) =>
                                                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                                                                active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                                                                            }`
                                                                                        }
                                                                                        value={data[i]}
                                                                                    >
                                                                                        {({selected}) => (
                                                                                            <>
                      <span
                          className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                          }`}
                      >
                        {data[i].name}
                      </span>
                                                                                                {selected ? (
                                                                                                    <span
                                                                                                        className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                        </span>
                                                                                                ) : null}
                                                                                            </>
                                                                                        )}
                                                                                    </Listbox.Option>
                                                                                ))}
                                                                            </Listbox.Options>
                                                                        </Transition>
                                                                    </div>
                                                                </Listbox>

                                                                <div className={`flex flex-row place-content-end`}>
                                                                    <button
                                                                        type="submit"
                                                                        className="flex items-center poppins-regular text-white bg-darkgreen rounded-xl xs:rounded-2xl xl:rounded-3xl py-2.5 px-7 xs:px-9 xs:py-3 xl:py-4 xl:px-8 mt-6 md:mt-10 xl:mt-12"
                                                                    >

                                                                        <h1 className={` text-xs xs:text-sm  lg:text-base xl:text-lg`}>Adaugă</h1>
                                                                    </button>
                                                                </div>
                                                            </form>


                                                        </div>
                                                    </div>
                                                </div>
                                            </Transition.Child>
                                        ) : null
                                }
                            </div>
                        </Dialog>
                    </Transition.Root>
                    <form onSubmit={handleSubmit}
                          className={`pb-20 xl:mx-auto w-full px-6 sm:px-8 lg:px-12 xl:px-40 2xl:px-50 3xl:px-80`}>

                        <h1 className={`poppins-bold text-2xl xs:text-3xl xl:text-4xl mt-8 sm:mt-10  lg:mt-14 xl:mt-16 2xl:mt-18`}>Editează
                            Caz</h1>
                        <div className={`flex flex-col sm:grid sm:grid-cols-2 sm:gap-8 lg:gap-12 xl:gap-20 lg:w-4/5`}>
                            <div className={`w-full`}>
                                <h2 className={`poppins-semi text-lg xs:text-xl xl:text-2xl mt-6 xl:mt-8`}>Imagine</h2>
                                <div className={`flex flex-row items-center space-x-4 mt-3 xl:mt-6`}>
                                    <ImageViewer>
                                        <img className={`w-50 h-16 lg:w-40 xl:h-18 rounded-3xl`} src={image} alt=""/>
                                    </ImageViewer>
                                    <input id="file" multiple className={``} type="file" onChange={(e) => {
                                        handleImage({image: e.target.files[0]})
                                    }}/>
                                    <div
                                        className={` poppins-regular text-xs xs:text-sm xl:text-base text-gray text-center px-10 py-3 xs:px-16 xs:py-5 sm:px-10 md:px-6 2xl:px-10 rounded-2xl xs:rounded-3xl border border-2 border-dashed mt-3 xl:mt-6`}>
                                        <label htmlFor="file">Faceți clic pentru a răsfoi sau trage și plasați
                                            fișierele</label></div>

                                </div>
                            </div>
                            <div className={`w-full`}>
                                <h2 className={`poppins-semi text-lg xs:text-xl xl:text-2xl mt-6 xl:mt-8`}>Titlu</h2>
                                <input type="text" id="titlu" placeholder="ex: Casa in constructie" value={title}
                                       onChange={handleChange("title")}
                                       className="w-full mt-2 sm:mt-6 xl:mt-8 border border-lightgray rounded-xl text-xs xs:text-sm xl:text-base xxs:rounded-2xl py-2 px-3 xxs:py-3 xxs:px-4"/>
                            </div>
                            <div className={`w-full`}>
                                <h2 className={`poppins-semi text-lg xs:text-xl xl:text-2xl mt-6 xl:mt-8`}>Nume/
                                    Actiune</h2>
                                <input type="text" id="nume" placeholder="Andrei/ Casa de locuit" value={name}
                                       onChange={handleChange("name")}
                                       className="w-full mt-2 sm:mt-6 xl:mt-8 border border-lightgray rounded-xl text-xs xs:text-sm xl:text-base xxs:rounded-2xl py-2 px-3 xxs:py-3 xxs:px-4"/>
                            </div>
                            <div className={`w-full`}>
                                <h2 className={`poppins-semi text-lg xs:text-xl xl:text-2xl mt-6 xl:mt-8`}>Suma</h2>
                                <input type="text" id="suma" placeholder="445" value={total_amount}
                                       onChange={handleChange("total_amount")}
                                       className="w-full sm:w-3/5 mt-2 sm:mt-6 xl:mt-8 border border-lightgray rounded-xl text-xs xs:text-sm xl:text-base xxs:rounded-2xl py-2 px-3 xxs:py-3 xxs:px-4"/>
                            </div>
                            <div>
                                <button
                                    onClick={() => {
                                        setOpenModal(true);
                                        setCurrentItem(3);
                                        setActiveItem({type: "addAdmin"});
                                    }}
                                    className="flex items-center poppins-regular text-white bg-darkgreen rounded-xl xs:rounded-2xl xl:rounded-3xl py-2.5 px-4 xs:px-6 xs:py-3 xl:py-4 xl:px-8 mt-6 md:mt-10 xl:mt-12"
                                ><h1 className={`ml-2 xl:ml-4 text-xs xs:text-sm  xl:text-lg`}>Adaugă Admin</h1>
                                </button>
                            </div>

                        </div>
                        <h2 className={`poppins-bold text-2xl lg:text-3xl mt-6 xl:mt-8`}>Admini</h2>
                        <div className={`mt-8 flex flex-row space-x-6`}>
                            {Object.keys(admins).map((u, i) => {
                                return (<div
                                        className={`flex flex-row space-x-10 border border-gray rounded-2xl px-4 py-3`}>
                                        <h3 className={`poppins-bold text-neutralblack text-lg`}>{admins[i].name}</h3>
                                        <button
                                            onClick={() => {
                                                removeAdmin({adminId: admins[i]._id})
                                            }}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 12L10 10L8 12ZM10 10L12 8L10 10ZM10 10L8 8L10 10ZM10 10L12 12L10 10ZM19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                                                    stroke="black" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={`mt-8 xl:mt-10`}>
                            <JoditEditor
                                ref={editor}
                                value={content}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                onChange={newContent => {
                                }}

                            /></div>
                        <div className={`flex flex-row place-content-end`}>
                            <button

                                type="submit"
                                className="flex items-center poppins-regular text-white bg-darkgreen rounded-xl xs:rounded-2xl xl:rounded-3xl py-2.5 px-7 xs:px-9 xs:py-3 xl:py-4 xl:px-8 mt-6 md:mt-10 xl:mt-12"
                            >

                                <h1 className={` text-xs xs:text-sm  lg:text-base xl:text-lg`}>Editează</h1>
                            </button>
                        </div>
                        <div className={`flex flex-row items-center justify-between mt-6 xl:mt-8`}>
                            <h1 className={`poppins-bold text-xl xs:text-2xl xl:text-3xl `}>Blogs</h1>
                            <button
                                onClick={() => {
                                    setOpenModal(true);
                                    setBlogContent("");
                                    setCurrentItem(2);
                                    setActiveItem({type: "addblog"});
                                }}
                                className="flex items-center bg-darkgreen rounded-full p-3 xs:p-3.5 xl:p-4"
                            >
                                <svg className={`w-4 h-4 xs:w-5 xs:h-5 xl:w-6 xl:h-6`} width="16" height="16"
                                     viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.3334 8.99984H1.66675M9.00008 1.6665V16.3332V1.6665Z" stroke="white"
                                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div className={`mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6 lg:grid-cols-3`}>
                            {Object.keys(blogs).map((u, i) => {
                                return (
                                    <button key={i}
                                            onClick={() => {
                                                setOpenModal(true);
                                                setBlogContent(formData.blogs[i].description);
                                                setBlogDetails(formData.blogs[i]);
                                                setCurrentItem(1);
                                                setActiveItem({type: "blog", key: i});
                                            }}
                                            className={`w-full flex flex-row space-x-1.5 relative border-1.5 border-gray rounded-3xl p-4 text-left`}
                                    >
                                        <p className={`w-full poppins-medium text-lg sm:text-lg line-clamp-2  overflow-ellipsis overflow-hidden`}
                                           dangerouslySetInnerHTML={{__html: formData.blogs[i].description}}></p>
                                        <svg
                                            className={`w-4 right-3`}
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13.232 3.23184L16.768 6.76784L13.232 3.23184ZM14.732 1.73184C15.2009 1.26294 15.8369 0.999512 16.5 0.999512C17.1631 0.999512 17.7991 1.26294 18.268 1.73184C18.7369 2.20074 19.0003 2.83671 19.0003 3.49984C19.0003 4.16297 18.7369 4.79894 18.268 5.26784L4.5 19.0358H1V15.4638L14.732 1.73184V1.73184Z"
                                                stroke="black"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>

                                    </button>

                                );
                            })}
                        </div>
                    </form>

                </div>
            </div>
        )
    }
}

export default SA_editNews;