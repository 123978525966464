import React, { useState, useEffect } from "react";
import axios from "axios";
import calendar from "../images/dashboard-vector-calendar.svg";
import card from "../images/dashboard-vector-card.svg";
import avatar from "../images/dashboard-avatar.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { isAuth } from "../helpers/auth.js";
import DashboardNavbar from "./DashboardNavbar";
import Settings from "./Settings";
import PropagateLoader from "../PropagateLoader.tsx";

const DashboardNews = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = () => {
    setIsLoading(true);
    axios
        .get(`${process.env.REACT_APP_API_URL}user/news_feeds`)
        .then((res) => {
          setNews(res.data);
          setFilteredData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
        });
  };

  const [filteredData, setFilteredData] = useState([]);
  const handleFilter = (event) => {
    const searchWord = event.target.value ? event.target.value : "";
    const newFilter = news.filter((news) => {
      return news.title.toLowerCase().includes(searchWord.toLowerCase());
    });
    setFilteredData(newFilter);
  };

  const navigate = useNavigate();

  if (isLoading) {
    return (<PropagateLoader/>)
  } else {
    return (
        <div className="">
          <DashboardNavbar/>
          <div className="2xl:container 2xl:mx-auto py-2 sm:py-4 xl:py-8">
            <div
                className="space-y-10 lg:space-y-0 lg:flex flex-row justify-between py-3 xs:py-4 px-3 xxs:px-4 xs:px-6 sm:px-12 lg:px-20  xl:px-24 2xl:px-0 lg:py-3">

              <Settings/>

              <div className="lg:w-2/3 2xl:w-3/4">
                <div className="w-full">
                  {isAuth()?.type === "admin" ? (
                      <div>
                        <div className="md:w-2/5 lg:w-1/2 xl:w-2/5 mb-4 relative">
                          <input
                              type="text"
                              placeholder="Search..."
                              onChange={handleFilter}
                              className="focus:outline-none border-button placeholder-darkgreen rounded-3xl poppins-semi text-base py-3 pl-8 w-full "
                          />
                          <div className="absolute top-3.5 right-5">
                            <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=""
                            >
                              <path
                                  d="M20.75 20.75L14.25 14.25L20.75 20.75ZM16.4167 8.83333C16.4167 9.82919 16.2205 10.8153 15.8394 11.7354C15.4583 12.6554 14.8997 13.4914 14.1956 14.1956C13.4914 14.8997 12.6554 15.4583 11.7354 15.8394C10.8153 16.2205 9.82919 16.4167 8.83333 16.4167C7.83748 16.4167 6.85137 16.2205 5.93132 15.8394C5.01127 15.4583 4.17528 14.8997 3.47111 14.1956C2.76693 13.4914 2.20835 12.6554 1.82725 11.7354C1.44615 10.8153 1.25 9.82919 1.25 8.83333C1.25 6.82211 2.04896 4.89326 3.47111 3.47111C4.89326 2.04896 6.82211 1.25 8.83333 1.25C10.8446 1.25 12.7734 2.04896 14.1956 3.47111C15.6177 4.89326 16.4167 6.82211 16.4167 8.83333Z"
                                  stroke="#20484F"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>

                        <div
                            className=" flex flex-col space-y-6 xs:space-y-8 sm:space-y-0 m-auto  mt-2.5 xs:mt-3 sm:mt-4 sm:grid  sm:grid-cols-2 xl:grid-cols-3 sm:gap-4 md:gap-8 lg:gap-6 xl:gap-4 ">
                          {filteredData.map((news, key) => {
                            return (
                                <div className=" flex flex-col place-items-start mt-0 py-2">
                                  <button
                                      onClick={() => {
                                        navigate(`/dashboard/newsfeed/details/${news._id}`);
                                      }}
                                      key={news._id}
                                      className="w-full bg-white border border-gray rounded-3xl sm:rounded-4xl lg:rounded-3xl"
                                  >
                                    <div className="relative">
                                      {" "}
                                      <div
                                          className="absolute poppins-900 w-1/5 xxs:w-1/6 sm:w-1/7 xl:w-1/5 top-3 right-3 text-sm xs:text-base">
                                        <CircularProgressbar
                                            value={news.percent}
                                            text={`${news.percent}%`}
                                            strokeWidth={13}
                                            background
                                            backgroundPadding={6}
                                            className="rounded-full "
                                            styles={buildStyles({
                                              backgroundColor: "#fff",
                                              textColor: "#20484F",
                                              pathColor: "rgba(32, 72, 79, 0.8)",
                                              trailColor: "transparent",
                                            })}
                                        />
                                      </div>
                                      <img
                                          src={avatar}
                                          alt=""
                                          className="w-full xxs:h-40 xs:h-44 sm:h-40 md:h-48 lg:h-40 rounded-3xl"
                                      />
                                    </div>
                                    <div className="p-4 text-md font-bold">
                                      <h1 className="text-left text-darkgreen">{news.title}</h1>
                                      <h2 className="line-clamp-1 text-left poppins-semi text-darkgreen mt-2 lg:mt-3 xl:mt-2"
                                          dangerouslySetInnerHTML={{__html: news.description}}>
                                      </h2>
                                      <div
                                          className="flex flex-row items-center mt-4 lg:mt-5 space-x-5 xs:space-x-12 sm:space-x-2 md:space-x-12 lg:space-x-6 2xl:space-x-4  sm:text-base md:text-lg lg:text-sm 2xl:text-xs">
                                        <div className="flex flex-row items-center">
                                          <img
                                              src={calendar}
                                              alt=""
                                              className="xs:w-6 xl:w-5"
                                          />
                                          <h3 className="poppins-bold text-darkgreen ml-2 sm:ml-1 md:ml-2 xl:ml-1 xs:mt-1 xl:mt-0">
                                            {news.date}
                                          </h3>
                                        </div>
                                        <div className="flex flex-row items-center">
                                          <img
                                              src={card}
                                              alt=""
                                              className="xs:w-7  xl:w-6"
                                          />
                                          <h3 className="poppins-bold text-darkgreen ml-2 sm:ml-1 md:ml-2 lg:ml-1 xs:mt-1 xl:mt-1">
                                            {news.total_amount} lei
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                            );
                          })}
                        </div>
                      </div>
                  ) : (
                      <div>
                        <h1
                            className="
        poppins-semi
        text-neutralblack
        text-lg
        xs:text-xl
        sm:text-2xl
        md:text-xl
        lg:text-2xl"
                        >
                          Cazuri noi
                        </h1>

                        <div
                            className="flex flex-col space-y-6 xs:space-y-8 sm:space-y-0 m-auto  mt-2.5 xs:mt-3 sm:mt-4 sm:grid  sm:grid-cols-2 xl:grid-cols-3 sm:gap-4 md:gap-8 lg:gap-6 xl:gap-4 ">
                          {news.map((news) => (
                              <button
                                  onClick={() => {
                                    navigate(`/dashboard/newsfeed/details/${news._id}`);
                                  }}
                                  key={news._id}
                                  className="bg-white border border-gray rounded-3xl sm:rounded-4xl lg:rounded-3xl"
                              >
                                <div className="relative">
                                  {" "}
                                  <div
                                      className="absolute poppins-900 w-1/5 xxs:w-1/6 sm:w-1/7 xl:w-1/5 top-3 right-3 text-sm xs:text-base">
                                    <CircularProgressbar
                                        value={news.percent}
                                        text={`${news.percent}%`}
                                        strokeWidth={13}
                                        background
                                        backgroundPadding={6}
                                        className="rounded-full "
                                        styles={buildStyles({
                                          backgroundColor: "#fff",
                                          textColor: "#20484F",
                                          pathColor: "rgba(32, 72, 79, 0.8)",
                                          trailColor: "transparent",
                                        })}
                                    />
                                  </div>
                                  <img
                                      src={avatar}
                                      alt=""
                                      className="w-full xxs:h-40 xs:h-44 sm:h-40 md:h-48 lg:h-40 rounded-3xl"
                                  />
                                </div>
                                <div className="p-4 text-md font-bold">
                                  <h1 className="text-left text-darkgreen">{news.title}</h1>
                                  <h2 className="line-clamp-1 text-left poppins-semi text-darkgreen mt-2 lg:mt-3 xl:mt-2"
                                      dangerouslySetInnerHTML={{__html: news.description}}>
                                  </h2>
                                  <div
                                      className="flex flex-row items-center mt-4 lg:mt-5 space-x-5 xs:space-x-12 sm:space-x-6 md:space-x-12 xl:space-x-6 2xl:space-x-4  sm:text-base md:text-lg lg:text-sm 2xl:text-xs">
                                    <div className="flex flex-row items-center">
                                      <img
                                          src={calendar}
                                          alt=""
                                          className="xs:w-6 sm:w-7 xl:w-5"
                                      />
                                      <h3 className="poppins-bold text-darkgreen ml-2 sm:ml-1 md:ml-2 xl:ml-1 xs:mt-1 xl:mt-0">
                                        {news.date}
                                      </h3>
                                    </div>
                                    <div className="flex flex-row items-center">
                                      <img src={card} alt="" className="xs:w-7 sm:w-8 xl:w-6"/>
                                      <h3 className="poppins-bold text-darkgreen ml-2 sm:ml-1 md:ml-2 lg:ml-1 xs:mt-1 xl:mt-1">
                                        {news.total_amount} lei
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </button>
                          ))}
                        </div>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default DashboardNews;
