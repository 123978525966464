import React, {useEffect, useState} from "react";
import {creditCard, getToken} from "../helpers/auth";
import {loadStripe} from "@stripe/stripe-js";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import axios from "axios";
import {useNavigate} from "react-router-dom";

function DashboardCard(url, config) {
    const navigate = useNavigate();
    let credit_card = creditCard()

    const [checkout, setCheckout] = useState(1)
    const AddCardForm = () => {
        const stripe = loadStripe(
            "pk_test_51KjLnwIKpe12arm6Ojasg7VW7cfvysr3Zss4fTNzzp5jyspk0mbmRFaAfxtCFek4RriiFWMCZZWIQdVhy0hCKUXX00lYe4Xlvw"
        );
        return (
            <Elements stripe={stripe}>
                <CheckoutForm/>
            </Elements>
        );
    };

    function CheckoutForm() {
        const [isPaymentLoading, setPaymentLoading] = useState(false);
        const stripe = useStripe();
        const elements = useElements();
        const addCard = async (e) => {
            e.preventDefault();
            if (!stripe || !elements) {
                return;
            }
            setPaymentLoading(true)
            // Save card at stripe
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),

            });

            setPaymentLoading(false);

            let card = {
                exp_month: result?.paymentMethod.card.exp_month,
                exp_year: result?.paymentMethod.card.exp_year,
                last4: result?.paymentMethod.card.last4,
            }
            // Set card on local storage
            localStorage.setItem('card', JSON.stringify(card))
            setCheckout(0)

            if (result.error) {
                alert(result.error.message);
            }
            // Save card in DB
            await axios.post(`${process.env.REACT_APP_API_URL}user/add/card`, {
                payment_method: result.paymentMethod.id
            }, {
                headers: {
                    Authorization: 'Bearer ' + getToken()
                }
            })
        };

        return (
            <form
                className="w-10/12 md:w-9/12"
                onSubmit={addCard}
            >
                <div className="relative  flex flex-col mt-20">
                    <CardElement
                        className="card w-full"
                        options={{
                            style: {
                                base: {
                                    backgroundColor: 'white',
                                    color: '#20484F'
                                },
                            },
                        }}
                    />
                    <div className="w-full flex justify-end mt-3">
                        <button
                            className="py-2 px-3 text-white font-medium rounded-2xl bg-darkgreen"
                            disabled={isPaymentLoading}
                        >
                            {isPaymentLoading ? "Loading..." : "Continua"}
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    const deleteCard = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}user/delete/card`, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            },
        })
            .then((result) => {
                localStorage.removeItem('card');
                navigate('/dashboard/account')
            })
            .catch((err) => {
                console.error(err)
            })
    }

    return (
        <div>
            <h1 className="poppins-semi  text-neutralblack text-lg xs:text-xl sm:text-2xl md:text-xl lg:text-2xl">
                Card</h1>
            {credit_card === null ? <div className="w-full flex flex-row">
                    {AddCardForm()}
                </div>
                :
                <div>
                    <div
                        className="flex flex-col border border-gray rounded-2xl xxs:rounded-3xl w-full mt-3 xs:mt-4 m-auto">
                        <h1 className="poppins-bold text-neutralblack text-base xxs:text-lg xs:text-xl mt-6 ml-5 xxs:mt-8 ">Cardul
                            de credit conectat</h1>
                        <form
                            className="flex flex-col poppins-regular text-sm xxs:text-base xs:text-lg mt-6 xs:mt-8 px-3">
                            <label htmlFor="ccnum" className="poppins-semi text-darkgray">Card number</label>
                            <div
                                className="text-lightgray mt-1 border border-lightgray  rounded-xl xxs:rounded-2xl py-2 px-3 xxs:py-3 xxs:px-4 ">****
                                **** **** {creditCard()?.last4}</div>
                            <div
                                className="flex flex-row w-full space-x-3 xs:space-x-4 md:space-x-6 lg:space-x-4 mt-4 xxs:mt-6">
                                <div className="flex flex-col w-full">
                                    <label htmlFor="ccnum" className="poppins-semi text-darkgray">Expiration
                                        Date</label>
                                    <div
                                        className="text-lightgray w-full mt-1 border border-lightgray rounded-xl xxs:rounded-2xl py-2 px-3 xxs:py-3 xxs:px-4">{creditCard()?.exp_month}/{creditCard()?.exp_year}</div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <label htmlFor="ccnum" className="poppins-semi text-darkgray">CVC</label>
                                    <input type="text" id="ccnum" name="cardnumber" placeholder="445"
                                           className="w-full mt-1 border border-lightgray rounded-xl xxs:rounded-2xl py-2 px-3 xxs:py-3 xxs:px-4"/>
                                </div>
                            </div>
                        </form>
                        <div
                            className="w-full flex place-content-end border-t mt-8 xxs:mt-10 xs:mt-12 pt-3 xxs:pt-4 xs:pt-5  pb-2 xxs:pb-3 px-3">
                            <button onClick={deleteCard}
                                    className="border border-error py-2 xxs:py-3 px-3 xxs:px-4 rounded-xl xxs:rounded-2xl poppins-bold text-error text-sm xxs:text-base xs:text-md">Șterge
                                cardul
                            </button>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default DashboardCard;