import React from "react";
import icon1 from "../images/Icon 1.svg";
import icon2 from "../images/Icon 2.svg";
import icon3 from "../images/Icon 3.svg";
import icon4 from "../images/Icon 4.svg";
import image from "../images/Imagekids.png";
import checkmark from "../images/Checkmark.svg";
import checkmark2 from "../images/Checkmark2.svg";

function Section1() {
    return (
        <div className="bg-white mt-10 md:mt-16">
            <div
                className="2xl:container 2xl:mx-auto flex flex-row items-center bg-white px-6 py-2 xxs:px-8 sm:px-12 lg:px-20 xl:px-24">
                <div className="md:w-1/2">
                    <div>
                        <h1 className="poppins-bold text-dark xs:w-4/5 md:w-auto text-base xs:text-lg sm:text-2xl md:text-lg lg:text-2xl xl:text-3xl">
                            Lorem Ipsum is simply dummy text of the printing and
                        </h1>
                        <h2 className="poppins-regular text-dark  mt-4 xxs:mt-6  md:mt-4  text-sm xs:text-base sm:text-xl md:text-sm lg:text-base xl:text-lg">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                        </h2>
                    </div>

                    <div
                        className="grid grid-rows-2 grid-cols-2 gap-x-5 gap-y-8 xs:gap-9 md:gap-y-6 mt-9 xxs:mt-8 sm:mt-14 md:mt-6 lg:mt-8 xl:mt-10 mb-12 sm:mb-20 md:mb-0 lg:pr-6">
                        <div>
                            <img
                                src={icon1}
                                alt=""
                                className=" w-9 xxs:w-10 xs:w-11 sm:w-14 md:w-10 lg:w-12 xl:w-14"
                            />
                            <h3 className=" poppins-bold text-dark mt-3 lg:mt-4 text-sm xs:text-base sm:text-xl md:text-base lg:text-lg xl:text-xl">
                                Lorem Ipsum
                            </h3>
                            <h4 className="poppins-regular text-dark mt-1.5 lg:mt-1 text-xs xs:text-sm sm:text-lg md:text-sm lg:text-base xl:text-lg">
                                Lorem Ipsum is simply dummy text of the printing.
                            </h4>
                        </div>
                        <div>
                            <img
                                src={icon2}
                                alt=""
                                className="w-9 xxs:w-10 xs:w-11 sm:w-14  md:w-10 lg:w-12 xl:w-14"
                            />
                            <h3 className="poppins-bold text-dark mt-3 lg:mt-4 text-sm xs:text-base sm:text-xl md:text-base lg:text-lg xl:text-xl">
                                Lorem Ipsum
                            </h3>
                            <h4 className="poppins-regular text-dark mt-1.5 lg:mt-1 text-xs  xs:text-sm sm:text-lg md:text-sm lg:text-base xl:text-lg">
                                Lorem Ipsum is simply dummy text of the printing.
                            </h4>
                        </div>
                        <div>
                            <img
                                src={icon3}
                                alt=""
                                className="w-9 xxs:w-10 xs:w-11 sm:w-14  md:w-10 lg:w-12 xl:w-14 "
                            />
                            <h3 className="poppins-bold text-dark mt-3 lg:mt-4 text-sm xs:text-base sm:text-xl md:text-base lg:text-lg xl:text-xl">
                                Lorem Ipsum
                            </h3>
                            <h4 className="poppins-regular text-dark mt-1.5 lg:mt-1 text-xs  xs:text-sm sm:text-lg md:text-sm lg:text-base xl:text-lg">
                                Lorem Ipsum is simply dummy text of the printing.
                            </h4>
                        </div>
                        <div>
                            <img
                                src={icon4}
                                alt=""
                                className="w-9 xxs:w-10 xs:w-11 sm:w-14  md:w-10 lg:w-12 xl:w-14"
                            />
                            <h3 className="poppins-bold text-dark mt-3 lg:mt-4 text-sm xs:text-base sm:text-xl md:text-base lg:text-lg xl:text-xl">
                                Lorem Ipsum
                            </h3>
                            <h4 className="poppins-regular text-dark mt-1.5 lg:mt-1 text-xs xs:text-sm sm:text-lg md:text-sm lg:text-base xl:text-lg">
                                Lorem Ipsum is simply dummy text of the printing.
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="md:w-1/2">
                    <div className="hidden md:flex items-center m-auto md:w-84 lg:w-108 xl:w-144">
                        <img src={image} alt="" className="relative ml-4 xl:w-132"/>

                        <div
                            className="absolute z-10 w-50 lg:w-56 xl:w-64 shadow-sm bg-darkgreen flex flex-row  rounded-xl xxs:rounded-2xl lg:rounded-3xl ml-40 lg:ml-68 xl:ml-84 -mt-50 md:-mt-52 lg:-mt-64 xl:-mt-76 py-3  lg:py-3 xl:py-4  px-2 xxs:px-3">
                            <img
                                src={checkmark2}
                                alt=""
                                className="w-7 lg:w-8  xl:w-10 -mt-2.5"
                            />
                            <div className="flex flex-col poppins-bold text-white ml-2 text-xs lg:text-sm xl:text-base">
                                <h3>Lorem ipsum</h3>
                                <svg
                                    viewBox="0 0 275 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mt-1.5 ml-1 w-32 lg:w-36 xl:w-40"
                                >
                                    <rect
                                        y="0.336182"
                                        width="273"
                                        height="10"
                                        rx="5.5"
                                        fill="#DCF2ED"
                                    />
                                </svg>
                                <svg
                                    viewBox="0 0 273 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mt-1.5 lg:mt-2 xl:mt-1.5 ml-1 w-32 lg:w-32 xl:w-36"
                                >
                                    <rect
                                        y="0.336182"
                                        width="190"
                                        height="11"
                                        rx="5.5"
                                        fill="#DCF2ED"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div
                            className="absolute z-10 w-50 lg:w-56 xxs:w-52 xl:w-68 shadow-sm bg-white flex flex-row  rounded-xl xxs:rounded-2xl lg:rounded-2xl ml-4 lg:ml-8 xl:ml-10 mt-52 xxs:mt-60 lg:mt-72 xl:mt-92 py-3 px-2 lg:py-3 xl:py-4 lg:px-2.5 xl:px-4 xxs:px-3">
                            <img
                                src={checkmark}
                                alt=""
                                className="w-7 lg:w-8 xl:w-10 -mt-2.5"
                            />
                            <div
                                className="flex flex-col poppins-bold text-darkgreen ml-2 text-xs lg:text-sm xl:text-base">
                                <h3>Lorem ipsum</h3>
                                <svg
                                    viewBox="0 0 273 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mt-1.5 ml-1 w-44 lg:w-52 xl:w-52"
                                >
                                    <rect
                                        width="190"
                                        height="9"
                                        rx="5.5"
                                        fill="#20484F"
                                        fillOpacity="0.1"
                                    />
                                </svg>
                                <svg
                                    viewBox="0 0 273 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mt-1 xl:mt-1.5 ml-1  w-32 lg:w-36 xl:w-40"
                                >
                                    <rect
                                        width="190"
                                        height="11"
                                        rx="5.5"
                                        fill="#20484F"
                                        fillOpacity="0.1"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section1;
