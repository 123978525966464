import React from "react";
import Navbar from "./Navbar";
import ForgotPasswordForm from "./forms/ForgotPasswordForm";
import Footer from "./Footer";

function ForgotPassword() {
    return (
        <div>
            <Navbar/>
            <ForgotPasswordForm/>
            <Footer />
        </div>
    )
}

export default ForgotPassword;