import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { getToken } from "../helpers/auth";
import SuperAdminNavbar from "./SuperAdminNavbar";
import PropagateLoader from "../PropagateLoader.tsx";

function SA_newsFeed() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}super_admin/news_feeds`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        setData(res.data);
        setIsLoading(false);

      })
      .catch((err) => {});
  };

  function formatDate(date) {
    const month = ["Ianuarie","Februarie","Martie","Aprilie","Mai","Iunie","Iulie","August","Septembrie","Octombrie","Noiembrie","Decembrie"];
    const d = new Date(date);
    let name = month[d.getMonth()];
    let day = "" + d.getDate();
    let year = d.getFullYear();
    let hours = d.getHours();
    let minutes = "0" + d.getMinutes();
    let formattedTime = hours + ":" + minutes.substr(-2);


    if (day.length < 2) day = "0" + day;

    return (<div><h1 className={`poppins-medium  text-base`}>{day} {name}, {year} </h1>
              <h2 className={`poppins-regular  text-sm text-gray`}>{formattedTime}</h2>
  </div>)
  }
  if (isLoading) {
    return (<PropagateLoader/>)
  } else {
    return (
        <div className={`h-screen bg-palegrey`}>
          <div className=" md:flex md:flex-row">
            <SuperAdminNavbar/>
            <div
                className={`m-auto md:w-2/3 lg:3/4  2xl:w-4/5 3xl:w-5/6  px-6 sm:px-8 lg:px-10 xl:px-12 2xl:px-16 3xl:px-64 mt-8 sm:mt-10`}
            >
              <div className={`flex flex-row justify-between items-center`}>
                <h1
                    className={`poppins-bold text-2xl xs:text-3xl xl:text-4xl   lg:mt-14 xl:mt-16 2xl:mt-18`}
                >
                  Cazuri
                </h1>
                <Link to="/super-admin/addnewsfeed">
                  <button
                      type="submit"
                      className="md:hidden  flex items-center poppins-regular text-white bg-darkgreen rounded-2xl py-3 px-6"
                  >
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M16.3334 8.99984H1.66675M9.00008 1.6665V16.3332V1.6665Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                      />
                    </svg>
                    <h1 className={`ml-4 text-xs`}>Adaugă caz</h1>
                  </button>
                </Link>
              </div>
              <div className={`flex flex-row justify-between`}>
                <div
                    className={`flex flex-row  items-center mt-4 lg:mt-6 md:w-2/5 lg:w-72 xl:w-2/5 relative`}
                >
                  <div className=" absolute top-3.5 left-5">
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M18.3333 18.3332L16.6666 16.6665M9.58329 17.4998C13.9555 17.4998 17.5 13.9554 17.5 9.58317C17.5 5.21091 13.9555 1.6665 9.58329 1.6665C5.21103 1.6665 1.66663 5.21091 1.66663 9.58317C1.66663 13.9554 5.21103 17.4998 9.58329 17.4998Z"
                          stroke="#9A9A9A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <input
                      type="text"
                      placeholder="Search"
                      className="ml-6 focus:outline-none placeholder-lightgray rounded-3xl poppins text-sm py-3 pl-8 w-full "
                  />
                </div>
                <Link to="/super-admin/addnewsfeed">
                  <button
                      type="submit"
                      className="hidden md:flex items-center poppins-regular text-white bg-darkgreen rounded-2xl py-3 px-6 mt-5"
                  >
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M16.3334 8.99984H1.66675M9.00008 1.6665V16.3332V1.6665Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                      />
                    </svg>
                    <h1 className={`ml-4 text-base`}>Adaugă caz</h1>
                  </button>
                </Link>
              </div>
              <div className={`w-full overflow-auto`}>
                <table className={`table-fixed overflow-x-auto w-full mt-10`}>
                  <thead className={`text-base`}>
                  <tr className={``}>
                    <th
                        className={`p-2 flex-nowrap w-16 poppins-medium text-gray text-center`}
                    >
                      ID
                    </th>
                    <th
                        className={`p-2 flex-nowrap w-60 poppins-medium text-gray text-left`}
                    >
                      Caz
                    </th>
                    <th
                        className={`p-2 flex-nowrap w-32 poppins-medium text-gray text-left`}
                    >
                      Suma
                    </th>
                    <th
                        className={`p-2 flex-nowrap w-32 poppins-medium text-gray text-left`}
                    >
                      Tip
                    </th>
                    <th
                        className={`p-2 flex-nowrap w-32 poppins-medium text-gray text-left`}
                    >
                      Status
                    </th>
                    <th
                        className={`p-2 flex-nowrap w-36 poppins-medium text-gray text-left`}
                    >
                      Creat la
                    </th>
                    <th
                        className={`p-2 flex-nowrap w-14 poppins-medium text-gray`}
                    >
                      Acțiune
                    </th>
                  </tr>
                  </thead>

                  {Object.keys(data).map((u, i) => {
                    return (
                        <tbody>
                        <tr className={``}>
                          <td key={i} className={`poppins-medium text-center`}>{i + 1}</td>
                          <td key={i} className={`flex flex-row items-center py-2`}>
                            <img
                                className={`w-10 h-8 rounded-xl `}
                                src={data[i].image}
                                alt=""
                            />
                            <div className={`ml-4`}>
                              <h1
                                  className={`truncate overflow-ellipsis overflow-hidden poppins-medium text-base`}
                              >
                                {data[i].title}
                              </h1>
                              <h1 className={`poppins-regular text-sm`}>
                                {data[i].admin}
                              </h1>
                            </div>
                          </td>
                          <td key={i} className={`poppins-medium text-base `}>
                            {data[i].total_amount} lei
                          </td>
                          <td key={i} className={`poppins-regular text-base`}>
                            {data[i].type}
                          </td>
                          <td key={i} className={`poppins-regular  text-base`}>
                            {data[i].status ? (
                                <button
                                    className={`bg-active poppins-regular text-white text-base rounded-xl py-1 px-2.5`}
                                >
                                  Activ
                                </button>
                            ) : (
                                <button
                                    className={`bg-inactive poppins-regular text-white text-base rounded-xl py-1 px-2.5`}
                                >
                                  Inactiv
                                </button>
                            )}
                          </td>
                          <td key={i}>
                            {formatDate(data[i].date)}
                          </td>
                          <td key={i} className={`poppins-regular text-base`}>
                            <button
                                onClick={() => {
                                  navigate(`/super-admin/editnewsfeed/${data[i]._id}`);
                                }}>
                              <svg
                                  className={`ml-7`}
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                    d="M13.232 3.23184L16.768 6.76784L13.232 3.23184ZM14.732 1.73184C15.2009 1.26294 15.8369 0.999512 16.5 0.999512C17.1631 0.999512 17.7991 1.26294 18.268 1.73184C18.7369 2.20074 19.0003 2.83671 19.0003 3.49984C19.0003 4.16297 18.7369 4.79894 18.268 5.26784L4.5 19.0358H1V15.4638L14.732 1.73184V1.73184Z"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                        </tbody>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default SA_newsFeed;
