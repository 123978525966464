import React, { useEffect, useState } from "react";
import axios from "axios";
import { getToken } from "../helpers/auth";
import { Link } from "react-router-dom";
import SuperAdminNavbar from "./SuperAdminNavbar";
import PropagateLoader from "../PropagateLoader.tsx";


function SA_dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}super_admin/dashboard`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {});
  };


  if (isLoading) {
          return (<PropagateLoader/>)
        } else {
          return(
              <div className={`h-screen bg-palegrey`}>
    <div className=" md:flex md:flex-row">
      <SuperAdminNavbar/>
      <div className={`bg-palegrey h-screen w-full`}>
        <div
            className={`grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4  justify-items-center sm:gap-8 md:w-144 lg:w-172 xl:w-240 2xl:w-320 mt-10 md:mt-0 space-y-10 sm:space-y-0  sm:px-10 md:py-18 3xl:m-auto `}
        >
          <button
              className={` flex flex-row items-center  bg-white  shadow-sm  w-4/5 sm:w-full lg:w-68 rounded-3xl px-5 py-4`}
          >
            <div className={`bg-blue1 rounded-full w-14 h-14`}>
              <svg
                  className={`m-auto mt-3 w-8 h-8`}
                  width="32"
                  height="18"
                  viewBox="0 0 36 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.2098 12.154C10.2606 10.9424 9.78601 9.5578 9.78601 8.00011C9.78601 5.34627 11.0742 3.15396 13.1081 1.82704C12.4301 0.788574 11.0742 0.0385742 9.31143 0.0385742C6.32833 0.0385742 4.63339 2.1155 4.63339 4.48088C4.63339 5.75011 5.10797 6.84627 6.12493 7.59627C6.66732 8.0578 7.1419 8.63473 7.1419 9.21165C7.1419 9.78857 6.87071 10.3655 5.17577 11.0001C2.73505 11.9232 0.497729 13.1924 0.429932 15.0963C0.497729 16.3655 1.4469 17.3463 2.87065 17.3463H5.10797C5.44696 17.3463 5.78595 17.1732 5.98934 16.8847C7.0741 15.2116 9.10803 14.1732 10.803 13.4232C11.4132 13.1924 11.5487 12.5578 11.2098 12.154Z"
                    fill="#6366F1"
                />
                <path
                    d="M31.0067 11.0001C29.3117 10.3655 29.0405 9.84626 29.0405 9.21165C29.0405 8.57703 29.5151 8.00011 30.0575 7.59627C31.0745 6.78857 31.549 5.75011 31.549 4.48088C31.549 2.1155 29.9219 0.0385742 26.871 0.0385742C25.1083 0.0385742 23.7523 0.788574 23.0065 1.82704C25.0405 3.15396 26.3286 5.34627 26.3286 8.00011C26.3286 9.5578 25.854 10.9424 24.9049 12.154C24.5659 12.6155 24.7693 13.1924 25.3117 13.4232C27.0066 14.1155 29.0405 15.2116 30.1253 16.8847C30.3287 17.1732 30.6677 17.3463 31.0067 17.3463H33.244C34.6677 17.3463 35.6169 16.3655 35.6169 15.0963C35.6847 13.1924 33.4474 11.9232 31.0067 11.0001Z"
                    fill="#6366F1"
                />
                <path
                    d="M22.5997 15.2115C20.7692 14.5192 20.4302 13.8846 20.4302 13.25C20.4302 12.5577 20.9726 11.923 21.5828 11.4615C22.6675 10.5961 23.2777 9.38458 23.2777 7.99996C23.2777 5.40381 21.4472 3.15381 18.1251 3.15381C14.803 3.15381 12.9725 5.40381 12.9725 7.99996C12.9725 9.38458 13.5826 10.5961 14.6674 11.4615C15.2776 11.9807 15.82 12.6153 15.82 13.25C15.82 13.9423 15.5488 14.5192 13.6504 15.2115C10.9385 16.1923 8.36223 17.2884 8.29443 19.3653C8.29443 20.75 9.51479 21.9038 11.0741 21.9038H18.1251H25.176C26.7354 21.9038 27.9557 20.75 27.9557 19.3653C27.888 17.3461 25.3116 16.25 22.5997 15.2115Z"
                    fill="#6366F1"
                />
              </svg>
            </div>
            <div
                className={`flex flex-col text-left space-y-1 poppins-bold text-blue1 ml-10`}
            >
              <div className={`text-xl`}>Utilizatori</div>
              <div>{data.users}</div>
            </div>
          </button>
          <Link to="/super-admin/newsfeed">
            <div
                className={` flex flex-row items-center  bg-white  shadow-sm  w-full lg:w-68 rounded-3xl px-5 py-4`}
            >
              <div className={`bg-blue2 rounded-full w-14 h-14`}>
                <svg
                    className={`m-auto mt-4 w-6 h-6`}
                    width="16"
                    height="22"
                    viewBox="0 0 20 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M6.25 19.25V16.75V19.25ZM10 19.25V14.25V19.25ZM13.75 19.25V11.75V19.25ZM16.25 24.25H3.75C3.08696 24.25 2.45107 23.9866 1.98223 23.5178C1.51339 23.0489 1.25 22.413 1.25 21.75V4.25C1.25 3.58696 1.51339 2.95107 1.98223 2.48223C2.45107 2.01339 3.08696 1.75 3.75 1.75H10.7325C11.064 1.75007 11.3819 1.88181 11.6163 2.11625L18.3838 8.88375C18.6182 9.11812 18.7499 9.43601 18.75 9.7675V21.75C18.75 22.413 18.4866 23.0489 18.0178 23.5178C17.5489 23.9866 16.913 24.25 16.25 24.25Z"
                      stroke="#3B82F6"
                      stroke-width="2.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                  className={`flex flex-col text-left space-y-1 poppins-bold text-blue2 ml-10`}
              >
                <div className={`text-xl`}>Cazuri</div>
                <div>{data.news_feeds}</div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>)
    }
}

export default SA_dashboard;
