import React from "react";
import {isAuth} from "../helpers/auth";
import {Link} from "react-router-dom";

function Settings() {
    return (
        <div className="">
            <h1 className="poppins-semi text-neutralblack text-lg xs:text-xl sm:text-2xl md:text-xl lg:text-2xl">
                Setări
            </h1>
            <div className=" sm:grid sm:grid-cols-2 gap-4 sm:gap-y-2 md:gap-x-8 lg:gap-0 lg:flex lg:flex-col">
                <Link to="/dashboard/newsfeed">
                    <button
                        className={`${window.location.pathname === "/dashboard/newsfeed" ? 'border-2 border-darkgreen bg-green' : 'border-1.5 border-gray'} w-full flex flex-row items-center mt-2.5 xs:mt-3 p-2 xs:p-2.5 sm:p-3  lg:p-3 bg-white border-solid border  rounded-2xl`}>
                        <div
                            className={`${window.location.pathname === "/dashboard/newsfeed" ? 'bg-darkgreen' : 'bg-lightestgray'} flex  rounded-xl w-10 h-10`}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" className="m-auto">
                                <path
                                    className={`${window.location.pathname === "/dashboard/newsfeed" ? 'stroke-white' : 'stroke-default'}`}
                                    d="M19 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4H15C15.5304 4 16.0391 4.21071 16.4142 4.58579C16.7893 4.96086 17 5.46957 17 6V7L19 20ZM19 20C18.4696 20 17.9609 19.7893 17.5858 19.4142C17.2107 19.0391 17 18.5304 17 18V7L19 20ZM19 20C19.5304 20 20.0391 19.7893 20.4142 19.4142C20.7893 19.0391 21 18.5304 21 18V9C21 8.46957 20.7893 7.96086 20.4142 7.58579C20.0391 7.21071 19.5304 7 19 7H17L19 20ZM13 4H9H13ZM7 16H13H7ZM7 8H13V12H7V8Z"
                                    stroke="#83859C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className="text-left flex flex-col ml-2.5">
                            <h1 className="poppins-bold text-sm xxs:text-base xs:text-lg sm:text-base md:text-lg lg:text-base">
                                Cazuri noi
                            </h1>
                            <h2 className="poppins-regular text-darkgray -mt-1 sm:mt-0 lg:-mt-1 text-xs xxs:text-sm xs:text-base sm:text-sm md:text-base lg:text-sm">
                                Vezi cele mai noi cazuri
                            </h2>
                        </div>
                    </button>
                </Link>
                <Link to="/dashboard/account">
                    <button
                        className={`${window.location.pathname === "/dashboard/account" ? 'border-2 border-darkgreen bg-green' : 'border-1.5 border-gray'} w-full flex flex-row items-center mt-2.5 xs:mt-3 p-2 xs:p-2.5 sm:p-3  lg:p-3 bg-white border-solid rounded-2xl`}>
                        <div
                            className={`${window.location.pathname === "/dashboard/account" ? 'bg-darkgreen' : 'bg-lightestgray'} w-full flex  rounded-xl w-10 h-10`}>
                            <svg className="m-auto" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    className={`${window.location.pathname === "/dashboard/account" ? 'stroke-white' : 'stroke-default'}`}
                                    d="M16.6668 17.5V15.8333C16.6668 14.9493 16.3156 14.1014 15.6905 13.4763C15.0654 12.8512 14.2176 12.5 13.3335 12.5H6.66683C5.78277 12.5 4.93493 12.8512 4.30981 13.4763C3.68469 14.1014 3.3335 14.9493 3.3335 15.8333V17.5"
                                    stroke="#83859C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    className={`${window.location.pathname === "/dashboard/account" ? 'stroke-white' : 'stroke-default'}`}
                                    d="M9.99984 9.16667C11.8408 9.16667 13.3332 7.67428 13.3332 5.83333C13.3332 3.99238 11.8408 2.5 9.99984 2.5C8.15889 2.5 6.6665 3.99238 6.6665 5.83333C6.6665 7.67428 8.15889 9.16667 9.99984 9.16667Z"
                                    stroke="#83859C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className="flex flex-col ml-2.5">
                            <h1 className="text-left poppins-bold text-sm xxs:text-base xs:text-lg sm:text-base md:text-lg lg:text-base">
                                Cont
                            </h1>
                            <h2 className="poppins-regular text-darkgray -mt-1 sm:mt-0 lg:-mt-1 text-xs xxs:text-sm xs:text-base sm:text-sm md:text-base lg:text-sm">
                                Informații Personale
                            </h2>
                        </div>
                    </button>
                </Link>

                {isAuth()?.type === "client" ? (<Link to="/dashboard/card">
                    <button
                        className={`${window.location.pathname === "/dashboard/card" ? 'border-2 border-darkgreen bg-green' : 'border-1.5 border-gray'} w-full flex flex-row items-center mt-2.5 xs:mt-3 p-2 xs:p-2.5 sm:p-3 lg:p-3 bg-white border-solid border rounded-2xl`}>
                        <div
                            className={`${window.location.pathname === "/dashboard/card" ? 'bg-darkgreen' : 'bg-lightestgray'} flex  rounded-xl w-10 h-10`}>
                            <svg className="m-auto" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    className={`${window.location.pathname === "/dashboard/card" ? 'stroke-white' : 'stroke-default'}`}
                                    d="M17.5002 3.33325H2.50016C1.57969 3.33325 0.833496 4.07944 0.833496 4.99992V14.9999C0.833496 15.9204 1.57969 16.6666 2.50016 16.6666H17.5002C18.4206 16.6666 19.1668 15.9204 19.1668 14.9999V4.99992C19.1668 4.07944 18.4206 3.33325 17.5002 3.33325Z"
                                    stroke="#83859C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    className={`${window.location.pathname === "/dashboard/card" ? 'stroke-white' : 'stroke-default'}`}
                                    d="M0.833496 8.33325H19.1668" stroke="#83859C" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <div className="flex flex-col ml-2.5 text-left">
                            <h1 className="poppins-bold text-sm xxs:text-base xs:text-lg sm:text-base md:text-lg lg:text-base">
                                Card
                            </h1>
                            <h2 className="poppins-regular text-darkgray -mt-1 sm:mt-0 lg:-mt-1 text-xs xxs:text-sm xs:text-base sm:text-sm md:text-base lg:text-sm">
                                Cardul de credit conectat
                            </h2>
                        </div>
                    </button>
                </Link>) : null}
                {/*<Link to="/dashboard/security">*/}
                {/*    <button*/}
                {/*        className={`${window.location.pathname === "/dashboard/security" ? 'border-2 border-darkgreen bg-green' : 'border-1.5 border-gray'} w-full flex flex-row items-center mt-2.5 xs:mt-3 p-2 xs:p-2.5 sm:p-3  lg:p-3 bg-white border-solid border  rounded-2xl`}>*/}
                {/*        <div*/}
                {/*            className={`${window.location.pathname === "/dashboard/security" ? 'bg-darkgreen' : 'bg-lightestgray'} flex  rounded-xl w-8 h-8 xxs:w-9 xxs:h-9 xs:w-10  xs:h-10  sm:w-11 sm:h-11  lg:w-10 lg:h-10`}>*/}
                {/*            <svg className="m-auto" width="20" height="20" viewBox="0 0 20 20" fill="none"*/}
                {/*                 xmlns="http://www.w3.org/2000/svg">*/}
                {/*                <path*/}
                {/*                    className={`${window.location.pathname === "/dashboard/security" ? 'stroke-white' : 'stroke-default'}`}*/}
                {/*                    d="M10.0002 18.3333C10.0002 18.3333 16.6668 15 16.6668 9.99996V4.16663L10.0002 1.66663L3.3335 4.16663V9.99996C3.3335 15 10.0002 18.3333 10.0002 18.3333Z"*/}
                {/*                    stroke="#83859C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>*/}
                {/*            </svg>*/}
                {/*        </div>*/}
                {/*        <div className="text-left flex flex-col ml-2.5">*/}
                {/*            <h1 className="poppins-bold text-sm xxs:text-base xs:text-lg sm:text-base md:text-lg lg:text-base">*/}
                {/*                Securitate*/}
                {/*            </h1>*/}
                {/*            <h2 className="poppins-regular text-darkgray -mt-1 sm:mt-0 lg:-mt-1 text-xs xxs:text-sm xs:text-base sm:text-sm md:text-base lg:text-sm">*/}
                {/*                Password, 2FA*/}
                {/*            </h2>*/}
                {/*        </div>*/}
                {/*    </button>*/}
                {/*</Link>*/}
                {isAuth()?.type === "client" ? (<Link to="/dashboard/paymenthistory">
                    <button
                        className={`${window.location.pathname === "/dashboard/paymenthistory" ? 'border-2 border-darkgreen bg-green' : 'border-1.5 border-gray'} w-full flex flex-row items-center mt-2.5 xs:mt-3 p-2 xs:p-2.5 sm:p-3 lg:p-3 bg-white border-solid border rounded-2xl`}>
                        <div
                            className={`${window.location.pathname === "/dashboard/paymenthistory" ? 'bg-darkgreen' : 'bg-lightestgray'} flex  rounded-xl w-10 h-10`}>
                            <svg className="m-auto" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    className={`${window.location.pathname === "/dashboard/paymenthistory" ? 'stroke-white' : 'stroke-default'}`}
                                    d="M16 11V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V11H16ZM5 9H19L20 21H4L5 9Z"
                                    stroke="#83859C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                        </div>
                        <div className="text-left flex flex-col ml-2.5">
                            <h1 className="poppins-bold text-sm xxs:text-base xs:text-lg sm:text-base md:text-lg lg:text-base">
                                Istoric Plăți
                            </h1>
                            <h2 className="poppins-regular text-darkgray -mt-1 sm:mt-0 lg:-mt-1 text-xs xxs:text-sm xs:text-base sm:text-sm md:text-base lg:text-sm">
                                Toate plățile efectuate
                            </h2>
                        </div>
                    </button>
                </Link>) : null}
            </div>
        </div>

    );
}

export default Settings;
