import React, { useEffect, useState } from "react";
import avatar from "../images/dashboard-avatar.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../helpers/auth";
import PropagateLoader from "../PropagateLoader.tsx";

function History() {
    const [isLoading, setIsLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    useEffect(() => {
        fetchPayments();
    }, []);

    const fetchPayments = () => {
        setIsLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}user/payments`, {
                headers: {
                    Authorization: "Bearer " + getToken(),
                },
            })
            .then((res) => {
                setPayments(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
            });
    };
    const navigate = useNavigate();

    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }

    if (isLoading) {
        return (<PropagateLoader/>)
    } else {
        return (
            <div>
                <h1 className="poppins-semi  text-neutralblack text-lg xs:text-xl sm:text-2xl md:text-xl lg:text-2xl">
                    Istoric Plăți
                </h1>
                <div
                    className="w-full m-auto  flex flex-col sm:grid grid-cols-2 gap-4  md:gap-6 lg:gap-0 lg:flex lg:flex-col space-y-2 sm:space-y-0 lg:space-y-4 mt-3 xs:mt-4 lg:mt-4">
                    {payments.map((payments) => (
                        <button
                            // onClick={() => {
                            //     navigate(`/dashboard/paymenthistory/${payments._id}`);
                            // }}
                            key={payments._id}
                            className="flex flex-col lg:flex-row bg-white items-center justify-between lg:border border-gray rounded-3xl lg:py-4 lg:px-6"
                        >
                            <div
                                className="w-full border border-gray rounded-3xl  lg:border-0 flex flex-col lg:flex-row">
                                <img
                                    src={avatar}
                                    alt=""
                                    className="w-full h-40 lg:w-40 lg:h-28 rounded-3xl  lg:rounded-2xl"
                                />
                                <div
                                    className="flex flex-col p-3 lg:p-0 space-y-1 lg:ml-4 lg:space-y-1 poppins-semi text-darkgreen text-base xs:text-lg lg:text-base">
                                    <h1 className="text-lg lg:text-base xs:text-xl text-left  ">
                                        {payments.title}
                                    </h1>
                                    <div className="flex ">
                                        <svg
                                            width="26"
                                            height="24"
                                            viewBox="0 0 26 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 xs:w-7 lg:w-5 mr-1 xs:mr-2 lg:mr-2"
                                        >
                                            <path
                                                d="M13.1878 7.33333C11.1735 7.33333 9.54102 8.3775 9.54102 9.66667C9.54102 10.9558 11.1735 12 13.1878 12C15.202 12 16.8345 13.0442 16.8345 14.3333C16.8345 15.6225 15.202 16.6667 13.1878 16.6667V7.33333ZM13.1878 7.33333C14.537 7.33333 15.7162 7.80233 16.347 8.5L13.1878 7.33333ZM13.1878 7.33333V6.16667V7.33333ZM13.1878 7.33333V16.6667V7.33333ZM13.1878 16.6667V17.8333V16.6667ZM13.1878 16.6667C11.8385 16.6667 10.6594 16.1977 10.0285 15.5L13.1878 16.6667ZM24.128 12C24.128 13.3789 23.845 14.7443 23.2952 16.0182C22.7454 17.2921 21.9395 18.4496 20.9236 19.4246C19.9078 20.3996 18.7017 21.1731 17.3744 21.7007C16.0471 22.2284 14.6244 22.5 13.1878 22.5C11.7511 22.5 10.3284 22.2284 9.00112 21.7007C7.6738 21.1731 6.46776 20.3996 5.45187 19.4246C4.43598 18.4496 3.63013 17.2921 3.08033 16.0182C2.53054 14.7443 2.24756 13.3789 2.24756 12C2.24756 9.21523 3.40018 6.54451 5.45187 4.57538C7.50355 2.60625 10.2862 1.5 13.1878 1.5C16.0893 1.5 18.872 2.60625 20.9236 4.57538C22.9753 6.54451 24.128 9.21523 24.128 12Z"
                                                stroke="#20484F"
                                                stroke-width="2.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <h1>{payments.amount}</h1>
                                    </div>
                                    <div className="flex">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 xs:w-7 lg:w-5 mr-1 xs:mr-2 lg:mr-2"
                                        >
                                            <path
                                                d="M6.33334 5.41667V1.75V5.41667ZM13.6667 5.41667V1.75V5.41667ZM5.41667 9.08333H14.5833H5.41667ZM3.58333 18.25H16.4167C16.9029 18.25 17.3692 18.0568 17.713 17.713C18.0569 17.3692 18.25 16.9029 18.25 16.4167V5.41667C18.25 4.93044 18.0569 4.46412 17.713 4.1203C17.3692 3.77649 16.9029 3.58333 16.4167 3.58333H3.58333C3.0971 3.58333 2.63079 3.77649 2.28697 4.1203C1.94315 4.46412 1.75 4.93044 1.75 5.41667V16.4167C1.75 16.9029 1.94315 17.3692 2.28697 17.713C2.63079 18.0568 3.0971 18.25 3.58333 18.25Z"
                                                stroke="#20484F"
                                                stroke-width="2.3"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>

                                        <h1>{formatDate(payments.created)}</h1>
                                    </div>
                                    <div className="flex">
                                        <svg
                                            width="21"
                                            height="17"
                                            viewBox="0 0 21 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 xs:w-7 lg:w-5 mr-1 xs:mr-2 lg:mr-2"
                                        >
                                            <path
                                                d="M1.875 6.58342H19.125H1.875ZM5.70833 11.3751H6.66667H5.70833ZM10.5 11.3751H11.4583H10.5ZM4.75 15.2084H16.25C17.0125 15.2084 17.7438 14.9055 18.2829 14.3663C18.8221 13.8272 19.125 13.0959 19.125 12.3334V4.66675C19.125 3.90425 18.8221 3.17298 18.2829 2.63382C17.7438 2.09465 17.0125 1.79175 16.25 1.79175H4.75C3.9875 1.79175 3.25624 2.09465 2.71707 2.63382C2.1779 3.17298 1.875 3.90425 1.875 4.66675V12.3334C1.875 13.0959 2.1779 13.8272 2.71707 14.3663C3.25624 14.9055 3.9875 15.2084 4.75 15.2084Z"
                                                stroke="#20484F"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <h1>**** **** **** {payments.last4}</h1>
                                    </div>
                                </div>
                            </div>
                            <svg
                                width="15"
                                height="25"
                                viewBox="0 0 15 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="hidden lg:block h-4"
                            >
                                <path
                                    d="M1.64746 1.7085L12.8916 12.5002L1.64746 23.2918"
                                    stroke="#20484F"
                                    stroke-width="2.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                    ))}
                </div>
            </div>
        );
    }
}

export default History;
