import React from "react";
import selected from "../images/avatar.png";
import {Link, useNavigate} from "react-router-dom";
import {isAuth} from "../helpers/auth";

function DashboardNavbar() {
  const navigate = useNavigate();
  function Logout() {

    localStorage.clear();
    navigate("/");

  }
  return (
    <div className="bg-darkgreen ">
      <div className="2xl:container 2xl:mx-auto">
        <div className=" flex flex-row justify-between items-center py-4 px-3 xxs:px-4 xs:px-6 sm:px-12 lg:px-20  xl:px-24 2xl:px-0">
          <Link to="/">
            <h1 className=" poppins-bold mt-1 text-white text-xl xs:text-2xl sm:text-3xl md:text-2xl xl:text-3xl">
              Aimila.
            </h1>
          </Link>
          <div className="flex flex-row items-center">
            <button onClick={Logout} className="poppins-bold text-white text-sm xs:text-base mr-3 xs:mr-4 sm:mr-5">Delogare</button>
              {isAuth()?.type === "client" ?
                  ( <Link to="/dashboard/paymenthistory"> <h2 className="poppins-bold text-white text-sm xs:text-base mr-3 xs:mr-4 sm:mr-5">
              Istoric Plăți
           </h2> </Link>) : null }

            <img
              src={selected}
              alt=""
              className="border-2 border-white rounded-xl xs:rounded-2xl sm:rounded-3xl md:rounded-2xl w-9 xxs:w-10 xs:w-12 sm:w-16 md:w-12"
            />{" "}
          </div>
        </div>

      </div>
    </div>
  );
}

export default DashboardNavbar;
