import React from "react";
import underline from "../images/Untitled_Artwork 3 1.svg";
import bg_img from "../images/BG Image.png";
import frame from "../images/Untitled_Artwork 2 1.svg";
import checkmark from "../images/Checkmark.svg";
import divider from "../images/divider.svg";
import {Link} from "react-router-dom";

function Header() {
    return (
        <div className="bg-palegrey relative">
            <div className="2xl:container 2xl:mx-auto relative">
                <div
                    className=" flex relative px-6 pt-12 pb-24 sm:pb-40 md:pb-24 lg:pb-36 xl:pb-44 xxs:px-8 sm:px-12 sm:pt-16 lg:px-20 xl:px-24 2xl:px-0">
                    <div className=" flex flex-col align-center relative md:w-1/2 xxs:mt-3 sm:mt-8 lg:mt-20 xl:mt-24">
                        <h2 className="poppins-bold text-darkgreen text-xl xs:text-2xl sm:text-3xl md:text-xl lg:text-2xl xl:text-3xl">
                            Ajută pe cei nevoiași
                        </h2>
                        <h1 className="relative z-10 poppins-bold text-dark w-68 xxs:w-76 xs:w-88 sm:w-120 xl:w-152 mt-2 xs:mt-3 md:mt-2 xl:mt-0 text-3xl xs:text-4xl sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                            Copiii au nevoie de ajutorul tău.
                        </h1>
                        <img
                            src={underline}
                            alt="underline"
                            className="absolute z-0 mt-16 xxs:mt-14 xs:mt-16 sm:mt-20 md:mt-12 lg:mt-17 xl:mt-22 ml-26 xxs:ml-14 xs:ml-16 sm:ml-24 md:ml-16 lg:ml-26 xl:ml-32 w-48 xxs:w-52 xs:w-64 sm:w-80 md:w-64 lg:w-76 xl:w-92"
                        />
                        <h3 className="poppins-regular z-10 text-dark  mt-6 xs:mt-9 sm:mt-12 md:mt-6 lg:mt-8 text-sm xs:text-base sm:text-2xl md:text-base lg:text-lg xl:text-xl">
                            Locul perfect în care îți poți arăta bunătatea și să te simți cu adevărat folositor pentru cei de lângă tine.
                        </h3>
                        <div className="flex mt-9 xxs:mt-10  sm:mt-16 md:mt-8 lg:mt-12">
                            <Link to="/signup">
                                <button
                                    className="poppins-regular text-white bg-darkgreen rounded-2xl xs:rounded-3xl py-3 sm:py-4 md:py-4 px-4 xxs:px-5 xs:px-6 sm:px-7 md:px-5 lg:px-9 lg:mr-6 text-xs xxs:text-sm xs:text-base sm:text-lg md:text-sm lg:text-base xl:text-lg">
                                    Înregistrează-te
                                </button>
                            </Link>
                            <Link to="/seemore">
                                <button
                                    className="poppins-regular text-dark bg-white rounded-2xl xs:rounded-3xl border-button py-3 sm:py-4 px-4 xs:px-5 sm:px-7 md:px-5 lg:px-9 ml-5 lg:ml-0 text-xs xxs:text-sm xs:text-base sm:text-lg md:text-sm lg:text-base xl:text-lg">
                                    Vezi mai multe
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className="hidden md:block m-auto ">
                        <img
                            src={bg_img}
                            alt=""
                            className="relative z-10 md:ml-16 lg:ml-34 -mt-18 lg:-mt-14 xl:-mt-23  w-64 lg:w-80 xl:w-100"
                        />
                        <img
                            src={frame}
                            alt=""
                            className="absolute w-84 lg:w-104 xl:w-128 -mt-68 lg:-mt-84 xl:-mt-104 ml-5 lg:ml-20 xl:ml-18"
                        />
                        <div
                            className="absolute z-10 w-64 lg:w-84 xl:w-104 shadow-sm bg-white flex flex-row rounded-2xl lg:rounded-3xl ml-5 lg:ml-16 xl:ml-12 -mt-3 lg:-mt-3 py-4 px-4 xl:py-5 xl:px-5">
                            <img
                                src={checkmark}
                                alt=""
                                className="w-9 lg:w-12 xl:w-14 -mt-2.5 "
                            />
                            <div
                                className="flex flex-col poppins-bold text-darkgreen  ml-3 lg:ml-4 text-sm lg:text-lg xl:text-xl">
                                <h3>Lorem ipsum is simply</h3>
                                <svg
                                    viewBox="0 0 273 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mt-1.5 lg:mt-2.5 xl:mt-3 ml-1 w-60 lg:w-80 xl:w-84"
                                >
                                    <rect
                                        width="190"
                                        height="9"
                                        rx="5.5"
                                        fill="#20484F"
                                        fillOpacity="0.1"
                                    />
                                </svg>
                                <svg
                                    viewBox="0 0 273 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mt-1.5 ml-1  w-32 lg:w-48 xl:w-48"
                                >
                                    <rect
                                        width="190"
                                        height="14"
                                        rx="5.5"
                                        fill="#20484F"
                                        fillOpacity="0.1"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute w-full bottom-0 left-0">
                <img src={divider} alt="" className=" h-full  w-full"/>
            </div>
        </div>
    );
}

export default Header;
