import React from "react";
import Navbar from "./Navbar.js";
import Header from "./HomePage/Header.js";
import Section1 from "./HomePage/Section1.js";
import Section2 from "./HomePage/Section2.js";
import Section3 from "./HomePage/Section3.js";
import Section4 from "./HomePage/Section4.js";
import Footer from "./Footer.js";

function HomePage() {
  return (
    <div className="">
      <Navbar />
      <Header />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
    </div>
  );
}

export default HomePage;
