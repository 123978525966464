import decorationTopLeft from "../images/DecorationTopLeft.svg";
import decorationBottomRight from "../images/DecorationBottomRight.svg";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

function ResetPasswordForm() {
  const params = useParams();
  const token = params.token;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    newPassword: "",
    password2: "",
  });
  const { newPassword, password2 } = formData;

  const reset = (e) => {
    e.preventDefault();
    if (newPassword !== "" && newPassword === password2) {
      axios
        .post(`${process.env.REACT_APP_API_URL}auth/reset/password/`, {
          newPassword,
          token,
        })
        .then((result) => {
          navigate("/signin");
        })

        .catch((err) => {});
    }
  };
  const handleChangeReset = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value });
  };

  return (
    <div className="relative bg-primarylight py-14 xs:py-18 lg:py-24">
      <img
        src={decorationTopLeft}
        alt=""
        className="hidden sm:block absolute top-0 left-0 sm:w-32 lg:w-40 xl:w-50"
      />
      <img
        src={decorationBottomRight}
        alt=""
        className="hidden sm:block absolute bottom-0 right-0 sm:w-32 lg:w-40 xl:w-50"
      />
      <div className="2xl:container 2xl:mx-auto relative">
        <div className="flex flex-col bg-white rounded-4xl  m-auto w-10/12 sm:w-8/12 md:w-3/6 lg:w-2/5 xl:w-2/5 px-4 sm:px-10 lg:px-13  py-7 lg:py-8 xl:py-12">
          <h1 className="text-center poppins-bold text-darkgreen text-xl md:text-2xl lg:text-3xl">
            Resetați parola
          </h1>

          <form
            onSubmit={reset}
            className="flex flex-col poppins-regular text-dark text-xs xs:text-sm lg:text-base"
          >
            <label className="mt-4 lg:mt-8">Parola</label>
            <input
              type="password"
              id="password"
              placeholder="Introduceți parola"
              className="  border-button placeholder-lightgray  rounded-2xl px-3 py-2.5 mt-2 focus:outline-none"
              name="password"
              value={newPassword}
              onChange={handleChangeReset("newPassword")}
              required
            />
            <label className="mt-4 lg:mt-8">Confirmați Parola</label>
            <input
              type="password"
              id="password"
              placeholder="Confirmați parola"
              className="  border-button placeholder-lightgray  rounded-2xl px-3 py-2.5 mt-2 focus:outline-none"
              name="password"
              value={password2}
              onChange={handleChangeReset("password2")}
              required
            />
            {newPassword !== password2 ? (
              <h1 className={`poppins-regular text-error  mt-2 md:mt-3`}>
                Parolele nu coincid.
              </h1>
            ) : null}

            <button
              type="submit"
              className="poppins-regular text-white bg-darkgreen rounded-xl py-3 lg:px-9 mt-10"
            >
              Trimite
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
