import React, { useState, useEffect } from "react";
import axios from "axios";
import calendar from "../images/dashboard-vector-calendar.svg";
import card from "../images/dashboard-vector-card.svg";
import avatar from "../images/dashboard-avatar.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import PropagateLoader from "../PropagateLoader.tsx";

const SeeMore = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [news, setNews] = useState([]);
  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}user/news_feeds`)
      .then((res) => {
        setNews(res.data);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  const navigate = useNavigate();

  if (isLoading) {
    return (<PropagateLoader/>)
  } else {
    return (
        <div>
          <Navbar/>
          <div className="bg-palegrey">
            <div className="2xl:container 2xl:mx-auto  sm:px-8 md:px-10 lg:px-18 xl:px-22">
              <div
                  className="flex flex-col space-y-6 xs:space-y-8 sm:space-y-0 m-auto py-6 xs:py-8 sm:py-12 w-11/12 xs:w-10/12  sm:w-auto  sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 md:gap-10 lg:gap-6 xl:gap-8 ">
                {news.map((news) => (
                    <button
                        onClick={() => {
                          navigate(`/dashboard/newsfeed/details/${news._id}`);
                        }}
                        key={news._id}
                        className="bg-white border border-gray rounded-3xl lg:rounded-4xl xl:rounded-3xl"
                    >
                      <div className="relative">
                        {" "}
                        <div
                            className="absolute poppins-900 w-1/5 xxs:w-1/6 xl:w-1/5 top-3 right-3 text-sm xs:text-base">
                          <CircularProgressbar
                              value={news.percent}
                              text={`${news.percent}%`}
                              strokeWidth={13}
                              background
                              backgroundPadding={6}
                              className="  border border-gray rounded-full "
                              styles={buildStyles({
                                backgroundColor: "#fff",
                                textColor: "#20484F",
                                pathColor: "rgba(32, 72, 79, 0.8)",
                                trailColor: "transparent",
                              })}
                          />
                        </div>
                        <img
                            src={avatar}
                            alt=""
                            className="w-full xxs:h-40 xs:h-44 sm:h-40 lg:h-40 xl:h-36 rounded-3xl xl:rounded-3xl"
                        />
                      </div>
                      <div className="p-4 text-sm xs:text-base sm:text-lg lg:text-base">
                        <h1 className="poppins-extra text-left text-darkgreen">
                          {news.title}
                        </h1>
                        <h2 className="line-clamp-2 text-left poppins-semi text-darkgreen mt-1.5"
                            dangerouslySetInnerHTML={{__html: news.description}}>
                        </h2>
                        <div
                            className="flex flex-row items-center mt-4 lg:mt-2 space-x-5 xs:space-x-12 sm:space-x-2 md:space-x-12 lg:space-x-6 sm:text-base md:text-lg lg:text-sm">
                          <div className="flex flex-row items-center">
                            <img
                                src={calendar}
                                alt=""
                                className="xs:w-6 xl:w-5"
                            />
                            <h3 className="poppins-bold text-darkgreen ml-2 sm:ml-1 md:ml-2 xl:ml-1 xs:mt-1 xl:mt-0">
                              {news.date}
                            </h3>
                          </div>
                          <div className="flex flex-row items-center">
                            <img src={card} alt="" className="xs:w-7 xl:w-6"/>
                            <h3 className="poppins-bold text-darkgreen ml-2 sm:ml-1 md:ml-2 lg:ml-1 xs:mt-1 xl:mt-1">
                              {news.total_amount} lei
                            </h3>
                          </div>
                        </div>
                      </div>
                    </button>
                ))}
              </div>
            </div>
          </div>
          <Footer/>
        </div>
    )
  }
}

export default SeeMore;
